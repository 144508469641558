import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from 'react-toastify';
import InfoCard from './InfoCard';

import { loadStripe } from '@stripe/stripe-js';


export default class Dashboard_main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataCards: [],
      page: 1,
      account_type: "2",
      selected_creators: 0,
      isActive: false,
      showForm: false,
      currentStep: 1,
      currentStepAmbassador: 1,
      startDate: '',
      requestType: '',
      Platform: '',
      requestInstructionsText: '',
      WebsiteUrl: '',
      file: null,
      count: 0,

      PayPerClick: "",
      PayPerCost: "",
      MonthBudget: "",
      url: "",
      Addfunds: 0,
      payData: [],


      balance: 0,
      post: 0,
      ambassador: 0,
      clicks: 0,


      content: [],
      campaigns: [],
      approved: false,
      selectedPhotoOption: 'Photos',
      selectedCampaignOption: 'Campaigns',
    };

  }
  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  }
  checkAccount = () => {
    if (this.state.account_type == window.localStorage.getItem("account")) {
      // console.log("matched", this.state.account_type, window.localStorage.getItem("account"));
      return true;
    }
  }
  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ approved: isChecked }, () => {
        this.fetchContent(isChecked);
    });
};

  handleSubmit_Ambassador = (event) => {
    event.preventDefault();

    const { PayPerClick, url, MonthBudget, PayPerCost } = this.state;
    const brand_id = this.state.data.id; // Replace with the actual brand ID
    const token = localStorage.getItem("accessToken");

    const apiUrl = `https://api.embassy.io/api/campaign/v1/ambassador/${brand_id}`;

    const requestData = {
      campaign_type: 5,
      budget: MonthBudget,
      details: {
        url: url,
        costPerClick: PayPerClick,
        costPerCost: PayPerCost
      }
    };

    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response data here
        console.log('Success:', data);
        toast.success('Changes Saved!');
        // Reload the page after successful API execution
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Error!');
      });
  }
  fetchAmbassadorDetails = (id) => {
    const token = localStorage.getItem("accessToken");

    const apiUrl = `https://api.embassy.io/api/campaign/v1/ambassador/${id}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(responseData => {
        // Adjusting the structure to match the actual API response
        const data = responseData.data;

        if (data && data.details) {
          this.setState({
            PayPerClick: data.details.costPerClick,
            url: data.details.url,
            MonthBudget: data.budget,
            PayPerCost: data.details.costPerCost
          });
        } else {
          console.error('Unexpected API response structure:', responseData);
        }
        console.log("this.state.PayPerClick:", this.state.PayPerClick);
      })
      .catch((error) => {
        console.error('Error fetching the data:', error);
      });
  }

  truncateAddress(address, wordLimit) {
    const parts = address.split(',');
    if (parts.length === 2) {
      return parts[1].trim();
    }
    const truncatedParts = parts.slice(-2);
    let result = truncatedParts.join(', ').trim();

    result = result.replace(/\b\d{5}(?:-\d{4})?\b/g, '').trim();

    return result;
  }
  formatNumber(number) {
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + 'B';
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + 'M';
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + 'K';
    } else {
      return number.toString();
    }
  }



  componentDidMount() {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
      const headers = new Headers({
        'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/user`, {
          method: 'GET',
          headers: headers
        });
        const result = await response.json();

        if (response.ok && result.status === 'SUCCESS') {
          this.setState({ data: result.data });
          this.fetchAmbassadorDetails(result.data.id);
        } else {
          if (result.code === 'token_not_valid') {
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
            console.error('Token is invalid or expired');
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
            window.location.href = '/signin'; // Automatically redirect to /signin
          } else {
            throw new Error('Failed to fetch data');
          }
        }
      } catch (error) {
        this.setState({ error: error.message });
        console.error(error.message);
      }
    };

    const fetchDashboardData = async () => {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
      const f_log = localStorage.getItem("f_log");
      console.log("f_log", f_log);
      if (f_log === "false") {
        console.log("setted value to false");
        this.setState({ currentStepAmbassador: 3 });

      }
      const headers = new Headers({
        'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
      });

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/dashboard`, {
          method: 'GET',
          headers: headers
        });
        const result = await response.json();

        if (response.ok && result.status === 'SUCCESS') {
          // Update the state with data from the response
          this.setState({
            balance: result.data.user.ambassador_balance,
            post: result.data.post,
            ambassador: result.data.ambassador,
            clicks: result.data.clicks,
            error: null
          });
        } else {
          if (result.code === 'token_not_valid') {
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
            window.location.href = '/signin'; // Automatically redirect to /signin
          } else {
            throw new Error('Failed to fetch data');
          }
        }
      } catch (error) {
        this.setState({ error: error.message });
        console.error(error.message);
      }
    };
    this.fetchContent();
    this.fetchDataCards(this.state.page);

    console.log("test values of ambassador prog: ", this.state.url)
    fetchDashboardData();
    fetchData();

  }
  handleImageError = (event, videoSrc) => {
    // Create a video element to replace the failed image
    const videoElement = document.createElement('video');
    videoElement.setAttribute('controls', 'true');
    videoElement.setAttribute('src', videoSrc);
    videoElement.setAttribute('style', 'height: 65vh; position: relative; z-index: 2;');

    // Replace the image with the video element
    event.target.replaceWith(videoElement);

    // Replace the background image with a video background
    const parentDiv = event.target.closest('.media-container');
    if (parentDiv) {
      parentDiv.style.backgroundImage = 'none';
      const backgroundVideoElement = document.createElement('video');
      backgroundVideoElement.setAttribute('autoplay', 'true');
      backgroundVideoElement.setAttribute('loop', 'true');
      backgroundVideoElement.setAttribute('muted', 'true');
      backgroundVideoElement.setAttribute('src', videoSrc);
      backgroundVideoElement.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; z-index: 1; filter: blur(40px);');
      parentDiv.insertBefore(backgroundVideoElement, parentDiv.firstChild);
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  nextStep = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  };


  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState(prevState => ({ currentStep: prevState.currentStep - 1 }));
    }
  };

  handleInputFormChange1 = (key, value, e) => {
    e.stopPropagation();
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });
  }
  nextStepA = () => {
    this.setState(prevState => ({ currentStepAmbassador: prevState.currentStepAmbassador + 1 }));
  };


  prevStepA = () => {
    if (this.state.currentStepAmbassador === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState(prevState => ({ currentStepAmbassador: prevState.currentStepAmbassador - 1 }));
    }
  };
  // currentStepAmbassador

  handleInputChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });

  }
  handleInputChangePayperClick = (key, value) => {
    // this.setState({ [key]: value }, () => {
    //   // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    // });
    if (value === '' || (Number(value) >= 0 && /^[0-9\b]*$/.test(value))) {
      this.setState({ [key]: value });
    }
  }

  handleToggleBTN = () => {
    if (this.state.isActive == true) {
      this.setState({ isActive: false });
    }
    else {
      this.setState({ isActive: true });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };



  encryptData = (payData, secretKey) => {
    // console.log("${process.env.REACT_APP_CRYPTO_ENC_TOKEN}: ",secretKey)
    // return CryptoJS.AES.encrypt(JSON.stringify(payData), secretKey).toString();
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(payData), secretKey, { mode: CryptoJS.mode.ECB });
    encrypted = encrypted.toString();
    // console.log('encrypted',encrypted );
    return encrypted

  }

  fetchDataCards = async (page) => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("accessToken");
    const headers = new Headers({
      'Authorization': `Bearer ${token}`
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/marketplace?page=${page}&page_size=4`, {
        method: 'GET',
        headers: headers
      });
      const result = await response.json();

      if (response.ok && result.status === 'SUCCESS') {
        this.setState(prevState => ({
          dataCards: page === 1 ? result.data.results : [...prevState.data, ...result.data.results], // Only append if not page 1
          isLoading: false,
          // Do not increment page here
        }));
      } else {
        if (result.code === 'token_not_valid') {
          window.localStorage.removeItem("refreshToken")
          window.localStorage.removeItem("accessToken")
          window.localStorage.removeItem("userName")
          window.localStorage.removeItem("isLogedin")
          console.error('Token is invalid or expired');
          window.localStorage.removeItem("refreshToken")
          window.localStorage.removeItem("accessToken")
          window.localStorage.removeItem("userName")
          window.localStorage.removeItem("isLogedin")
          window.location.href = '/signin';
        } else {
          throw new Error('Failed to fetch data');
        }
      }
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
      console.error(error.message);
    }
  };

  renderCards() {
    const { dataCards } = this.state;
    return dataCards.map(user => (
      <div className="col-md-3 col-sm-12 mb-3 " key={user.id} data-toggle="modal" data-target={`#modal-${user.id}`} style={{ cursor: "pointer" }} >
        <div className="card" style={{ background: "#120c34", color: "white", borderRadius: "25px" }}>
          <div style={{ position: 'relative' }}>
            {/* <div className="text-center " style={{ backgroundImage: `url(${user.images ? user.images[0] : "/images/person.png"})`,borderRadius: "25px",backgroundSize: "cover",backgroundPosition: "center" }}>
              <img className="original-image" src={user.images ? user.images[0] : "/images/person.png"} alt="User" style={{ height: "35vh" }} />
            </div> */}
            <div className="text-center" style={{ position: "relative", borderRadius: "25px", overflow: "hidden" }}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${user.images ? user.images[0] : "/images/person.png"})`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "25px",
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1
                }}
              ></div>
              <img
                className="original-image"
                src={user.images ? user.images[0] : "/images/person.png"}
                alt="User"
                style={{
                  height: "35vh",
                  position: "relative",
                  zIndex: 2,
                  // borderTopRightRadius:"25px",
                  // borderTopLeftRadius:"25px"
                }}
              />
              {user.favorite ? (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => this.onClickSelectedCreators(event, user.id)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                      fill="red"
                      style={{
                        width: "48px",
                        height: "48px"
                      }}
                    >
                      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                    </svg>
                  </button>
                </a>
              ) : (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => this.onClickSelectedCreators(event, user.id)}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3
                  }}
                >
                  <i className="fa-regular fa-heart fa-3x" style={{ color: "white" }}></i>
                </a>
              )}
            </div>



            <div class="blur-effect"></div>
            <div class="shade-effect "></div>
          </div>
          <div>
            <div className="card-body1  px-3 pt-2">
              <p className="card-text alignleft font-type" style={{ fontWeight: "800" }}>{user.name}</p>
              <small className="card-text alignright">{user.location ? this.truncateAddress(user.location, 5) : "No location"} <i className="fa-solid fa-location-dot"></i></small>
            </div>
            <div className="card-body2 px-3  d-grid">
              {/* Render social links dynamically if available */}
              <a href="#" style={{ textDecoration: 'none' }}>
                <img src="/images/instagram.png" alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <p>{user.social && user.social.instagram ? this.formatNumber(user.social.instagram) : '0'}</p>
              <br />
              <a href="#" style={{ textDecoration: 'none' }}>
                <img src="/images/tiktok.png" alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <p>{user.social && user.social.tiktok ? this.formatNumber(user.social.tiktok) : '0'}</p>
              <br />
            </div>
            <div className="d-grid px-3 mb-2">
              {/* Additional buttons or information */}
              {user.company ? (
                <button className='small-rounded-button mr-1 px-1'><small>{user.company}</small></button>
              ) : (
                <>
                  <button className='small-rounded-button mr-1 px-1'><small>Fitness</small></button>
                  <button className='small-rounded-button mr-1 px-1'><small>FootBall</small></button>
                  <button className='small-rounded-button mr-1 px-1'><small>Dance</small></button>
                </>
              )}
              <small>+2 more</small>
            </div>
          </div>
          <div className="modal fade bd-example-modal-lg p-0" id={`modal-${user.id}`} tabIndex="-1" role="dialog"
            aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ borderRadius: "25px" }}>
            <div className="modal-dialog modal-lg" role="document" style={{ color: "white", borderRadius: "25px", maxWidth: "700px" }}>
              <div className="modal-content" style={{ borderRadius: "25px" }}>
                <div className="modal-body p-0" onClick={e => this.handleInputFormChange1('userID', user.id, e)} style={{ borderRadius: "25px" }}>
                  {this.renderModalContent(user)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade bd-example-modal-lg p-0" id={`modal-${user.id}`} tabIndex="-1" role="dialog"
          aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ borderRadius: "25px" }}>
          <div className="modal-dialog modal-lg" role="document" style={{ color: "white", borderRadius: "25px", maxWidth: "700px" }}>
            <div className="modal-content" style={{ borderRadius: "25px" }}>
              <div className="modal-body p-0" onClick={e => this.handleInputFormChange1('userID', user.id, e)} style={{ borderRadius: "25px" }}>
                {this.renderModalContent(user)}
              </div>
            </div>
          </div>
        </div>



      </div>

    ));
  }



  onClickSelectedCreators = async (event, userId) => {
    event.stopPropagation();

    // Update the state with the new selected_creators count
    this.setState(prevState => ({
      selected_creators: event.target.checked ? prevState.selected_creators + 1 : prevState.selected_creators - 1
    }), () => {
      // console.log(this.state.selected_creators); // This logs the updated state value

      // Proceed with the API call after state update
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error('Access token not found');
        return;
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/general/v1/favorite`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const body = JSON.stringify({ creator: userId });

      // Call the API
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
          }
          return response.json(); // Parse the JSON response
        })
        .then(data => {
          // console.log(data); // Handle successful response
          toast.success('Added to favorite! ');
        })
        .catch(error => {
          console.error('Error calling API:', error);
        });
    });
  };

  handleCheckout = async (event) => {
    event.preventDefault();
    console.log("Stripe called");

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const stripe = await stripePromise;
    const isLogedin = window.localStorage.getItem("accessToken");

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${isLogedin}`
        },
        body: JSON.stringify({ amount: parseFloat(this.state.Addfunds) }) // Convert to cents
      });

      const { data: { id: sessionId } } = await response.json();
      console.log("sessionId: ", sessionId);

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Error:', error);
      }
    } catch (error) {
      console.error('Checkout error:', error);
    }
  }


  renderAmbassdorProgramne() {
    const { currentStepAmbassador, PayPerClick, url, MonthBudget, PayPerCost } = this.state;
    // currentStepAmbassador
    switch (currentStepAmbassador) {
      case 1:
        return (
          <>
            <div className="container-fluid">
              <h5 className="text-center set_font_mainPage mt-3" style={{ color: "#120c34" }}>Welcome to Embassy!</h5>
              <h4 className="text-center " style={{ color: "#120c34" }}>Embassy allow you to grow effortlessly by advertising through your brand community.</h4>

              <img className='img-fluid' src="/images/Popup.png" alt="" />
              <div className="row">
                <div className="col-12 pr-5" style={{ display: "flex", justifyContent: "end" }}>
                  <h4 className='mt-5' onClick={this.nextStepA} style={{ cursor: "pointer" }}>Next <i class="fa-solid fa-right-long"></i> </h4>
                </div>
              </div>
            </div>

          </>
        )
      case 2:
        return (
          <>
            <div className="container-fluid">
              <div>
                <h5 className="text-center set_font_mainPage " style={{ color: "#120c34" }}>Explore Features</h5>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>Brand Embassy</h4>
                <div className="card border border-0">
                  <div className="card-body p-2" style={{ border: "1px solid #120c34", borderRadius: "25px" }}>
                    <h4 className=" text-center" style={{ color: "#120c34" }}>Your brand embassy hosts an ongoing ambassador through which your customer are paid per click for traffic they drive to your website. <br />Just add funds they'll do the rest. </h4>
                  </div>
                </div>
              </div>
              {/*  */}
              <div>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>Campaigns</h4>
                <div className="card border border-0">
                  <div className="card-body p-2" style={{ border: "1px solid #120c34", borderRadius: "25px" }}>
                    <h4 className=" text-center" style={{ color: "#120c34" }}>Create custom campaigns, in which target participamnts are compensated for genrating either content or driving traffic for your brand. </h4>
                  </div>
                </div>
              </div>
              {/*  */}
              <div>
                <h4 className="text-center mt-3" style={{ color: "#120c34" }}>Marketplace</h4>
                <div className="card border border-0">
                  <div className="card-body p-2" style={{ border: "1px solid #120c34", borderRadius: "25px" }}>
                    <h4 className=" text-center" style={{ color: "#120c34" }}>Submit requests directly to specific creator.</h4>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <Link onClick={this.nextStepA} className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block', cursor: "pointer" }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Get Started
                  </span>
                </Link>
              </div>

            </div>
          </>
        )
      case 3:
        return (
          <>
            <div className="container-fluid text-center">
              <h5 className="text-center set_font_mainPage mb-4" style={{ color: "#120c34" }}>Brand Embassy <InfoCard infoText="Your brand Embassy is an ongoing program in which your ambassadors are componsated per click for traffic they drive to a custom url." /></h5>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="card p-0" style={{ width: "80%", backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: "25px" }}>
                  <div>
                    <div className="row p-0">
                      <div className="col-6 col-md-6 col-sm-12 p-4 text-center text-white">
                        <h4 className='my-2 name_font' style={{ fontWeight: "600" }}>${this.state.balance}</h4>
                        <div style={{ width: "100%", height: "3px", background: "white" }}></div>
                        <h5 className='my-2 name_font' style={{ fontWeight: "800" }}>Remaining Budget</h5>
                      </div>
                      <div className="col-6 col-md-6 col-sm-12 p-4 d-flex align-items-center justify-content-center">
                        <button type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} onClick={this.nextStepA} >Add Funds</button>
                        {/* <Link type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} to="/make-payment">Manage</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={this.handleSubmit_Ambassador}>
                <div className="row">
                  <div className="col-md-6 mt-3 text-center">
                    <h3 className='text-center' style={{}}>Payment per Click <InfoCard infoText="This is the amount that ambassdor paid for each click they drive to your website." /> </h3>
                    <div style={{ position: 'relative', width: '50%', display: 'inline-block' }}>
                      <span style={{ position: 'absolute', left: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: 'x-large' }}>$</span>
                      <input
                        type="number"
                        value={PayPerClick}
                        onChange={e => {
                          const value = e.target.value;
                          if (value === '' || (Number(value) > 0 && !/^0+$/.test(value))) {
                            this.handleInputChangePayperClick('PayPerClick', value);
                          }
                        }}
                        style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", paddingLeft: '30px' }}
                        placeholder="0.50"
                        className="input-box rounded-pill p-2 text-center"
                        min="0.01"
                        step="0.01"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3 text-center">
                    <h3 className='text-center' style={{}}>Payment per Post <InfoCard infoText="This is the amount that the participant will be paid for each piece of content they create and you approve." /></h3>
                    <div style={{ position: 'relative', width: '50%', display: 'inline-block' }}>
                      <span style={{ position: 'absolute', left: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: 'x-large' }}>$</span>
                      <input
                        type="number"
                        value={PayPerCost}
                        onChange={e => {
                          const value = e.target.value;
                          if (value === '' || (Number(value) > 0 && !/^0+$/.test(value))) {
                            this.handleInputChangePayperClick('PayPerCost', value);
                          }
                        }}
                        style={{
                          border: "#120c34 2px solid",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          paddingLeft: '30px'
                        }}
                        placeholder="0.50"
                        className="input-box rounded-pill p-2 text-center"
                        min="0.01"
                        step="0.01"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3 text-center">
                    <h3 className='text-center' style={{}}>URL <InfoCard infoText="This is url to which ambassadors will drive traffic." /> </h3>
                    <input type="text" value={url} onChange={e => this.handleInputChange('url', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "80%" }} placeholder="www.customdomain.com" className="input-box rounded-pill p-2 text-center" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3 text-center">
                    <h3 className='text-center' style={{}}>Monthly Budget <InfoCard infoText="At the beginning of each month, your embassy will automatically refill back to your monthly budget." /></h3>
                    <div style={{ position: 'relative', width: '30%', display: 'inline-block' }}>
                      <span style={{ position: 'absolute', left: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: 'x-large' }}>$</span>
                      <input
                        type="number"
                        value={MonthBudget}
                        onChange={e => this.handleInputChange('MonthBudget', e.target.value)}
                        style={{
                          border: "#120c34 2px solid",
                          borderRadius: "25px",
                          fontSize: "x-large",
                          width: "100%",
                          paddingLeft: '30px'
                        }}
                        placeholder="10,000"
                        className="input-box rounded-pill p-2 text-center"
                      />
                    </div>
                  </div>
                </div>


                <div className="text-center mt-4">
                  <button className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block', cursor: "pointer" }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Save Changes
                    </span>
                  </button>
                </div>
              </form>


            </div>
          </>
        )
      case 4:
        return (
          <>
            <div className="container-fluid text-center">
              <div className="row">
                <div className="col-1">
                  <button className='bg-transparent border-0' onClick={this.prevStepA}>
                    ← Back
                  </button>
                </div>
              </div>

              <h5 className="text-center set_font_mainPage mb-4" style={{ color: "#120c34" }}>Add Funds</h5>
              <h4 className="text-center mt-3" style={{ color: "#120c34" }}>How much would you like to add?</h4>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1 text-center">
                <div style={{ position: 'relative', width: '30%', display: 'inline-block' }}>
                  <span style={{ position: 'absolute', left: '15px', top: '50%', transform: 'translateY(-50%)', fontSize: 'x-large' }}>$</span>
                  <input
                    type="number"
                    onChange={e => this.handleInputChangePayperClick('Addfunds', Math.max(100, e.target.value))}
                    style={{
                      border: "#120c34 2px solid",
                      borderRadius: "25px",
                      fontSize: "x-large",
                      width: "100%",
                      paddingLeft: '30px'
                    }}
                    placeholder="8,000"
                    className="input-box rounded-pill p-2 text-center"
                    min="100"
                    value={Math.max(100, this.state.Addfunds || 10)}
                  />
                </div>
              </div>
            </div>
            <h5 className="text-center mt-3" style={{ color: "#120c34" }}>Save up to $800 by <Link style={{ color: "#90b5e9" }} onClick={this.nextStepA} >Subscribe today</Link></h5>
            <h4 className="text-center mt-5" style={{ color: "#120c34" }}>Note: <br />These funds will be added to your budget, but are only spent as participants genrate clicks.</h4>

            <div className="text-center mt-4">
              <button onClick={this.handleCheckout} className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block', cursor: "pointer" }}>
                <span style={{
                  color: '#fff',
                  lineHeight: 'inherit',
                  cursor: 'pointer',
                  // backgroundColor: '#3898ec',
                  border: '0',
                  borderRadius: '0',
                  padding: '9px 15px',
                  textDecoration: 'none',
                  display: 'inline-block'
                }}>
                  Continue to payment
                </span>
              </button>
            </div>

          </>
        )
      case 5:
        return (
          <>
            <div className="container-fluid">
              <h5 className="text-center set_font_mainPage mt-3" style={{ color: "#120c34" }}>Limited offer</h5>
              <h4 className="text-center " style={{ color: "#120c34" }}>Subscribe today and receive 30 days free and $100 towards your brand embassay ambassador progam.</h4>
              <h5 className="text-center set_font_mainPage mt-3" style={{ color: "#120c34" }}>Subscription</h5>
              <div className="table-responsive p-0 mx-0">
                <table className="table" style={{ borderTop: '2px solid #120c34', borderBottom: '2px solid #120c34' }}>
                <tbody>
                    <tr style={{ borderTop: '2px solid #120c34' }}>
                      <th><h4 className='text-center'>Tier</h4></th>
                      <th><h4 className='text-center'>Feature</h4></th>
                      <th><h4 className='text-center'>Price</h4></th>
                    </tr>

                  
                  <tr style={{ borderTop: '2px solid #120c34', borderBottom: '2px solid #120c34', borderCollapse: 'separate', borderSpacing: '0' }}>
                      <td><h5>Micro</h5></td>
                      <td className='text-center'><h5>brand embassy : 15% transaction fee <br/> campaign : 15% transaction fee <br/>Marketplace : Unlock creator with less then 100k followers</h5></td>
                      <td><button style={{
                          borderRadius: '25px',
                          border: '2px solid #120c33',
                          padding: '5px 10px',
                          backgroundColor: 'transparent',
                          color: '#120c33',
                          cursor: 'pointer'
                        }}
                        className='btn btn-lg '>
                          $99/month
                        </button></td>
                    </tr>
                    <tr style={{ borderTop: '2px solid #120c34', borderBottom: '2px solid #120c34' }}>
                      <td><h5>Macro</h5></td>
                      <td className='text-center'><h5>brand embassy : 10% transaction fee <br/> campaign : 10% transaction fee <br/>Marketplace : Unlock creator with less then 10M followers</h5></td>
                      <td>
                        <button style={{
                          borderRadius: '25px',
                          border: '2px solid #120c33',
                          padding: '5px 10px',
                          backgroundColor: 'transparent',
                          color: '#120c33',
                          cursor: 'pointer'
                        }}
                        className='btn btn-lg '>
                          $249/month
                        </button></td>
                    </tr>
                    <tr style={{ borderTop: '2px solid #120c34', borderBottom: '2px solid #120c34' }}>
                      <td><h5>Mega</h5></td>
                      <td className='text-center'><h5>brand embassy : 5% transaction fee <br/> campaign : 5% transaction fee <br/>Marketplace : Unlock  all creators</h5></td>
                      <td>
                        <button style={{
                          borderRadius: '25px',
                          border: '2px solid #120c33',
                          padding: '5px 10px',
                          backgroundColor: 'transparent',
                          color: '#120c33',
                          cursor: 'pointer'
                        }}
                        className='btn btn-lg '>
                          $999/month
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <div className="text-center mt-3">
              <button
                style={{
                  borderRadius: '25px',
                  border: '2px solid #120c34',
                  padding: '10px 20px',
                  backgroundColor: 'transparent',
                  color: '#120c34',
                  cursor: 'pointer',
                  fontSize: 'x-large',
                  fontWeight: 'bold'
                }}
                className='btn'
              >
                Decline offer
              </button>
            </div>
            </div>

          </>
        )

    }
  }

  renderForm() {
    const { currentStep, startDate, requestType, selectedLogo, requestInstructions, url, file, count } = this.state;
    switch (currentStep) {
      case 1:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar" >
              <li className="active" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li id="step2"><strong></strong></li>
              <li id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            {/* <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required /> */}
            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Start Date</h3>
            <input type="text" onChange={e => this.handleInputChange('showDate', e.target.value)} name="startDate" className="input-box text-center rounded-pill mx-3" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="xx/xx/xxx" />

            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Type</h3>
            <div className="row mt-3">
              <div className="col-6 col-sm-6">
                <input onClick={e => this.handleInputChange('requestType', e.target.value)} type="button" className=" rounded-pill mx-3 selected" style={{ color: this.state.requestType === 'Posted Content' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.requestType === 'Posted Content' ? 'white' : 'transparent', width: '60%', padding: '15px', borderRadius: "25px", }} value="Posted Content" />
              </div>
              <div className="col-6 col-sm-6">
                <input onClick={e => this.handleInputChange('requestType', e.target.value)} type="button" className=" rounded-pill mx-3 " style={{ color: this.state.requestType === 'UGC' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.requestType === 'UGC' ? 'white' : 'transparent', width: '50%', padding: '15px', borderRadius: "25px" }} value="UGC" />
              </div>
            </div>

            <div className="text-center  ">
              <h3 className='mt-5' style={{ fontWeight: "800" }}>Platform</h3>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo1">
                      <img src="/images/instagram.png" alt="Instagram Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo1" name="logo" value="instagram" />

                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo2">
                      <img src="/images/tiktok.png" alt="TikTok Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo2" name="logo" value="tiktok" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo3">
                      <img src="/images/youtube.png" alt="YouTube Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo3" name="logo" value="youtube" />
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Cancel" />
              </div>
              <div className="col-6 col-sm-6">
                <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Continue
                  </span>
                </button>

              </div>
            </div>

          </div>
        );
      case 2:
        const maxLength = 400;
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar" >
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='active' id="step2"><strong></strong></li>
              <li id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Instruction</h3>

            <div className=" mx-5">
              <div className=" position-relative" style={{ marginBottom: '20px' }}>
                <textarea
                  onChange={e => this.handleInputChange('requestInstructionsText', e.target.value)}
                  className="form-control"
                  style={{
                    height: '20em',
                    borderRadius: '25px',
                    resize: 'none'
                  }}
                  placeholder="Tell the creators what you'd like to post"
                  maxLength={maxLength}
                />
                <div className="position-absolute" style={{
                  bottom: '5px',
                  right: '10px',
                  fontSize: '12px',
                  color: 'gray'
                }}>
                  {/* Placeholder text, since we're not managing state here */}
                  <b style={{ color: "#120c34", }}>{`${maxLength}/${maxLength} characters remaining`}</b>
                </div>
              </div>
            </div>

            <div className="card mx-5 mt-2 px-2" style={{ borderRadius: '25px', }}>
              <div className="d-flex align-items-center">
                <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4>
                <input onChange={e => this.handleInputChange('WebsiteUrl', e.target.value)} className='border  border-0 form-control' style={{ border: '0', borderRadius: '25px' }} type="text" placeholder="www.yourwebiste.com" aria-label="Username" aria-describedby="basic-addon1" />
              </div>
            </div>

            <div className="card mx-5 mt-3 px-2" style={{ borderRadius: '25px', }}>
              <div className="d-flex">
                <h4 className='my-2 ' style={{ color: "#120c34" }}><b>attachment (optinal):</b></h4>
                <input type="file" id="fileInput" style={{ margin: '10px 0', display: 'none' }} />
                <label htmlFor="fileInput" className="" style={{ cursor: 'pointer', color: "#120c34" }}>
                  <div className="d-flex align-items-center gap-3">
                    <i className="fa fa-upload my-2 me-2" style={{ fontSize: "1.5em" }}></i>
                    <h4 className='my-2'>Click to Upload</h4>
                  </div>
                </label>
              </div>
            </div>


            <div className="row mt-5 pb-3">
              <div className="col-md-6">
                <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Back" />
              </div>
              <div className="col-md-6">
                <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Continue
                  </span>
                </button>

              </div>
            </div>

          </div>

        );
      case 3:
        const increment = () => {
          const input = document.getElementById('countInput');
          let currentValue = parseInt(input.value, 10);
          input.value = currentValue + 1;
        };

        const decrement = () => {
          const input = document.getElementById('countInput');
          let currentValue = parseInt(input.value, 10);
          input.value = currentValue - 1;
        };
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='active' id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>

            <div className="container-fluid mt-3">

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className='my-3' style={{ fontWeight: "600" }}>in-feed photo post </h2>
                </div>

                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-center " style={{ width: '70%', borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={decrement} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent' id="countInput" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={increment} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className='my-3' style={{ fontWeight: "600" }}>in-feed photo post </h2>
                </div>

                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-center " style={{ width: '70%', borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={decrement} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent' id="countInput" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={increment} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className='my-3' style={{ fontWeight: "600" }}>in-feed photo post </h2>
                </div>

                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-center " style={{ width: '70%', borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={decrement} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent' id="countInput" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={increment} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-md-6 align-items-center justify-content-center text-right">
                  <h2 className='my-3' style={{ fontWeight: "600" }}>in-feed photo post </h2>
                </div>

                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-center " style={{ width: '70%', borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={decrement} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent' id="countInput" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={increment} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <h3 className='mt-3' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>

              <div className="row mt-3">
                <div className="col-md-5" >
                  <div className="dropdown show" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    {/* <a className="btn btn-light bg-white dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> */}
                    <div className="card border-0 text-center p-0" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="text-center ">
                        <h3 className='dropdown-toggle' style={{ fontWeight: "600" }}>1 post</h3>
                      </div>
                    </div>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <h2 className='my-3' style={{ fontWeight: "600" }}>per </h2>
                </div>
                <div className="col-md-5" >
                  <div className="dropdown show" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    {/* <a className="btn btn-light bg-white dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> */}
                    <div className="card border-0 text-center p-0" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      <div className="text-center ">
                        <h3 className='dropdown-toggle' style={{ fontWeight: "600" }} >1 post</h3>
                      </div>
                    </div>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>


            </div>


            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-md-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Back" />
                </div>
                <div className="col-md-6">
                  <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Continue
                    </span>
                  </button>

                </div>
              </div>
            </div>


          </div>
        );
      case 4:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='' id="step3"><strong></strong></li>
              <li className='active' id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>
            <input type="button" className="btn btn-primary rounded-pill mx-3" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="$2,000" />

            <h3 className='mt-3' style={{ fontWeight: "800" }}>product included</h3>
            <div className="row mt-3">
              <div className="col-md-6">
                <input type="button" className=" rounded-pill mx-3 selected" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="Yes" />
              </div>
              <div className="col-md-6">
                <input type="button" className=" rounded-pill mx-3 bg-transparent text-white" style={{ fontWeight: "800", border: "2px solid white", fontSize: "x-large", backgroundColor: '', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="No" />
              </div>
            </div>

            <h3 className='mt-3' style={{ fontWeight: "800" }}>product URL</h3>
            <div className="card mx-5 mt-2 px-2" style={{ borderRadius: '25px', }}>
              <div className="row align-items-center">
                <div className="col-md-12">
                  {/* <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4> */}
                  <input className='border  border-0 form-control' style={{ fontWeight: "800", fontSize: "x-large", border: '0', borderRadius: '25px' }} type="text" placeholder="www.yourwebiste.com" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>

            <h3 className='mt-3' style={{ fontWeight: "800" }}><b>product value</b></h3>
            <input type="text" className="text-center input-box rounded-pill mx-3" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="$0" />



            <div className="container">
              <div className="row mt-5 pb-3">
                <div className="col-md-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ fontWeight: "800", fontSize: "x-large", border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="Back" />
                </div>
                <div className="col-md-6">
                  <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block',
                      fontWeight: "800", fontSize: "x-large"
                    }}>
                      Continue
                    </span>
                  </button>

                </div>
              </div>
            </div>
          </div>

        );
      case 5:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='' id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li className='active' id="step5"><strong></strong></li>
            </ul>

            <h3 className='mt-3' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row text-center">

                    <div className="col-md-12 mb-1 text-center">
                      <h5 className='text-center' style={{ fontWeight: "800" }}><b>select deliverable</b></h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-2">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>


                    <div className="col-md-12 mb-1 text-center">
                      <h5 className='text-center' style={{ fontWeight: "800" }}><b>select deliverable</b></h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mb-1 text-center">
                    <h5 className='text-center' style={{ fontWeight: "800" }}><b>select deliverable</b></h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <textarea
                        className='pt-2'
                        style={{
                          textAlign: 'center',
                          borderRadius: "25px",
                          fontSize: 'large', // Note: Adjusted to use a string value instead of "large"
                          border: 'none', // Adjusted to use 'none' instead of "0"
                          fontWeight: '800',
                          width: "100%",
                          color: '#120c34', // Applied to the textarea itself
                          resize: 'none', // Prevents the user from resizing the textarea
                          overflow: 'hidden', // Ensures the text does not overflow the textarea
                          height: '150px', // Equivalent to 5 rows of text (assuming an average line height)
                        }}
                        rows={5}
                      >
                        text here
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <h5 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h5>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center gap-3">
                        <i className="fa fa-upload my-2 me-2" style={{ fontSize: "1.5em", color: "#120c34" }}></i>
                        <h4 className='my-2' style={{ color: "#120c34" }}>Click to Upload</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 text-center">
                    <h5 className='text-center' style={{ fontWeight: "800" }}><b>select deliverable</b></h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>text here</p>
                      </div>
                    </div>
                  </div>



                </div>
              </div>


            </div>
            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-md-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ fontWeight: "800", fontSize: "x-large", border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="Back" />
                </div>
                <div className="col-md-6">
                  <button type="submit" className='btn btn-lg form-button' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block',
                      fontWeight: "800", fontSize: "x-large"
                    }}>
                      Submit
                    </span>
                  </button>

                </div>
              </div>
            </div>

          </div>
        );
      default:
        return null;
    }
  }


  fetchContent = async (approved = false) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const query = approved ? '?approved=true' : '';
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content${query}`, {
      headers: {
        'Authorization': `Bearer ${isLogedin}`,
      },
    });
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ content: result.data.results });
    }
  };
  fetchContent1 = async (approved = false, cam_id) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const query = approved ? '?approved=true' : '';
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content${query}&campaign=${cam_id}`, {
      headers: {
        'Authorization': `Bearer ${isLogedin}`,
      },
    });
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ content: result.data.results });
    }
  };
  fetchCampaigns = async () => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/dropdown-campaign?campaign_type=3,4`, {
      headers: {
        'Authorization': `Bearer ${isLogedin}`,
      },
    });
    const result = await response.json();
    if (result.status === "SUCCESS") {
      this.setState({ campaigns: result.data });
    }
  };
  handleApprove = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/approve/${contentId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${isLogedin}`,
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content approved successfully');
      toast.success('Content approved! ');
    } else if (result.status === "FAILURE" && result.message === "Content action previously taken") {
      toast.error('You have already taken an action on this content');
    } else {
      // Handle error
      console.error('Error approving content');
    }
  };

  handleReject = async (contentId) => {
    const isLogedin = window.localStorage.getItem("accessToken");
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content/reject/${contentId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${isLogedin}`,
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    if (result.status === "SUCCESS") {
      // Handle success (e.g., update the state or show a notification)
      // console.log('Content rejected successfully');
      toast.error('Content Rejected! ');
    } else if (result.status === "FAILURE" && result.message === "Content action previously taken") {
      toast.error('You have already taken an action on this content');
    } else {
      // Handle error
      console.error('Error rejecting content');
    }
  };

  renderModalContent(user) {
    if (this.state.showForm) {
      // return <form>{this.renderForm()}</form>;
      return (
        <form onSubmit={this.handleSubmit}>
          {/* <input type="text" onChange={e => this.handleInputFormChange1('userId', user.id, e)} style={{display:"none"}} /> */}
          {this.renderForm()}
        </form>
      );
    } else {
      return (
        <div style={{ borderRadius: "25px" }}>
          {/* <img src={user.images && user.images.length > 0 ? user.images[0] : "/images/person.png"} className='' alt="" style={{ width: "100%", height: "64vh", borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} /> */}
          <div style={{ position: 'relative' }}>
            <div className="text-center" style={{ position: "relative", overflow: "hidden" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ zIndex: "10000000000", position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', fontSize: '1.5rem', color: '#fff' }}
              >
                <i class="fa-solid fa-xmark fa-2x" style={{ color: "white" }}></i>
              </button>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${user.images ? user.images[0] : "/images/person.png"})`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1
                }}
              ></div>
              <img
                className="original-image"
                src={user.images ? user.images[0] : "/images/person.png"}
                alt="User"
                style={{
                  height: "65vh",
                  position: "relative",
                  zIndex: 2
                }}
              />
            </div>
            <div class="blur-effect"></div>
            <div class="shade-effect "></div>


          </div>
          <div className="shade-effect1"></div>

          <div className='profile' style={{ borderRadius: "25px", background: "#120c34" }}>

            <div className="card-body1  px-3 pt-4" style={{ background: "#120c34" }}>
              <h3 className="card-text alignleft name_font"><b> {user.name}</b></h3>
              <h3 className="card-text alignright location_font" style={{ fontWeight: "bold", fontSize: "1.1rem" }}>{user.location ? this.truncateAddress(user.location, 5) : "No location"}&nbsp;<i className="fa-solid fa-location-dot"></i></h3>
            </div>


            <div className="card-body2 px-3  d-grid py-2">
              <a href="" style={{ textDecoration: 'none' }}>
                <img src="/images/instagram.png" className='social_logo' alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <h3 className='social_font' style={{ fontWeight: "bold" }}>{user.social && user.social.instagram ? user.social.instagram.toLocaleString() : '0'}</h3>
              <br />
              <a href="" style={{ textDecoration: 'none' }}>
                <img src="/images/tiktok.png" className='social_logo' alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <h3 className='social_font' style={{ fontWeight: "bold" }}>{user.social && user.social.tiktok ? user.social.tiktok.toLocaleString() : '0'}</h3>
            </div>

            <div className="d-grid px-3 mb-2">
              <button className='small-rounded-button-modal mr-3 px-2' style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>cool</button>
              <button className='small-rounded-button-modal mr-3 px-2' style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>cool</button>
              <button className="small-rounded-button-modal mr-3 px-2" style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>Click Me</button>
            </div>

            <div className="text-center pb-3">
              <button className='btn btn-lg form-button' onClick={this.toggleForm} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                <span style={{
                  color: '#fff',
                  lineHeight: 'inherit',
                  cursor: 'pointer',
                  padding: '9px 15px',
                  textDecoration: 'none',
                  display: 'inline-block'
                }}>
                  Start Request
                </span>
              </button>
            </div>

          </div>

        </div>

      );
    }
  }

  render() {
    const { content, campaigns, approved } = this.state;
    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }
    if (!this.checkAccount()) {
      // console.log("matched and runned");
      return <Navigate to="/home" replace />;
    }
    const randomNumber1 = Math.floor(Math.random() * 100);
    const randomNumber2 = Math.floor(Math.random() * 100);
    const randomNumber3 = Math.floor(Math.random() * 100);
    const { data } = this.state;
    return (
      <div>
        <div className="container-fluid mt-3 px-md-5">

          <div className="container-fluid p-4 mt-4" style={{ background: "#120c34", borderRadius: "25px" }}>
            <div className="row m-0">
              <div className="col-md-4 col-sm-12 mb-4">
                <h4 className="text-center text-white set_font_mainPage">Brand {data.name}</h4>
                <div className="card p-0" style={{ backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: "25px" }}>
                  <div>
                    <div className="row p-0">
                      <div className="col-6 col-md-6 col-sm-12 p-4 text-center text-white">
                        <h4 className='my-2 name_font' style={{ fontWeight: "600" }}>${this.state.balance}</h4>
                        <div style={{ width: "100%", height: "3px", background: "white" }}></div>
                        <h5 className='my-2 name_font' style={{ fontWeight: "800" }}>Remaining Budget</h5>
                      </div>
                      <div className="col-6 col-md-6 col-sm-12 p-4 d-flex align-items-center justify-content-center">
                        <button type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} data-toggle="modal" data-target="#exampleModalCenter" >Manage</button>
                        {/* <Link type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} to="/make-payment">Manage</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-12 mx-auto ">
                <h5 className="text-center text-white set_font_mainPage">Ambassador Performance</h5>
                <div className="row p-2">
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className='my-2' style={{ fontWeight: "600" }}>{this.state.ambassador}</h4>
                    <div style={{ width: "100%", height: "3px", backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)' }}></div>
                    <h4 className='my-2 name_font' style={{ fontWeight: "800" }}>Ambassador</h4>
                  </div>
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className='my-2' style={{ fontWeight: "600" }}>{this.state.post}</h4>
                    <div style={{ width: "100%", height: "3px", backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)' }}></div>
                    <h4 className='my-2 name_font' style={{ fontWeight: "800" }}>Post</h4>
                  </div>
                  <div className="col-4 col-sm-4 p-md-4 text-center text-white">
                    <h4 className='my-2' style={{ fontWeight: "600" }}>{this.state.clicks}</h4>
                    <div style={{ width: "100%", height: "3px", backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)' }}></div>
                    <h4 className='my-2 name_font' style={{ fontWeight: "800" }}>Clicks</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12 col-sm-12 text-right">
                <Link to="/content_library" type="button" className="btn bg-white btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                  View Deliverables
                </Link>
              </div>
            </div> */}
          </div>


          {/* <div className="row mt-4">
            <div className="col-md-12">
              <h3 className='px-2 my-3 text-center'>Brand Management</h3>
            </div>
            <div className="col-4 col-sm-4 px-2">

              <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                <Link to="/content_library" type="button" className="btn bg-transparent btn-lg name_font" style={{ color: "#120c34", width: "100%", border: "#120c34 3px solid", borderRadius: "25px" }}>
                  Content Library
                  <span className="notification-badge">{0}</span>
                </Link>
              </div>
            </div>

            <div className="col-4 col-sm-4">
              <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                <Link to="/inbox" type="button" className="btn bg-transparent btn-lg name_font" style={{ color: "#120c34", width: "100%", border: "#120c34 3px solid", borderRadius: "25px" }}>
                  Inbox
                  <span className="notification-badge">{0}</span>
                </Link>
              </div>
            </div>

            <div className="col-4 col-sm-4">
              <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                <Link to="/manage_req" type="button" className="btn bg-transparent btn-lg name_font" style={{ color: "#120c34", width: "100%", border: "#120c34 3px solid", borderRadius: "25px" }}>
                  Manage Req.
                  <span className="notification-badge">{0}</span>
                </Link>
              </div>
            </div>

          </div> */}

          {/* <div className="row mt-5">
            <div className="col-md-12">
              <div className="row align-items-center my-3">
                <div className="col-md-9">
                  <h3 className='px-2'>Trending Creators</h3>
                </div>
              </div>
            </div>            
            {this.renderCards()}
          </div> */}




          <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ borderRadius: "25px" }}>
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document" >
              <div class="modal-content" style={{ borderRadius: "25px" }}>
                <div class="modal-body">
                  {this.renderAmbassdorProgramne()}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="mt-4 mb-4 px-3 " style={{ backgroundColor: "#120c34", borderRadius: "25px", marginLeft: "10px", marginRight: "10px" }}>
          <br />

          <div className="row">
            <div className="col-12 col-md-3  ">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <p className="Sw-Choice text-white" >All</p>
                <label className="switch">
                  <input type="checkbox" checked={approved} onChange={this.handleCheckboxChange} />
                  <span className="slider round"></span>
                </label>
                <p className="Sw-Choice text-white" >Approved</p>
              </div>
            </div>
            <div className='col-md-6'>
            <h3 className='px-2 text-center text-white ' >Embassy Library</h3>
              </div> 
          </div>


          <div className="row mt-3 bg-light p-2 m-1" style={{ borderRadius: "25px" }}>

            {content.map((item, index) => (


              <div className="col-md-3 col-sm-6 mb-3 px-1" key={index}>
                <div data-toggle="modal" data-target={`#modal-${item.id}`}>
                  <img src={item.image} className="img-fluid" style={{
                    height: "400px", width: "400px",
                    borderTopLeftRadius: index === 0 ? "25px" : "0",
                    borderTopRightRadius: index === 3 ? "25px" : "0",
                  }} alt="Content" onError={(e) => { e.target.src = "/images/videologo.png"; e.target.alt = "Fallback Image"; }} />
                </div>



                <div className="modal fade bd-example-modal-lg" id={`modal-${item.id}`} tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "25px" }}>
                    <div className="modal-content" style={{ borderRadius: "25px" }}>
                      <div className="modal-body p-0" style={{ background: "#120c34" }}>
                        <div style={{ borderRadius: "25px" }}>
                          {/* <img src={item.image} className='img-fluid' alt="" style={{ width: '100%', height: 'auto', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} /> */}
                          <div style={{ position: 'relative' }}>
                            <div className="text-center" style={{ position: "relative", overflow: "hidden" }}>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{ zIndex: "10000000000", position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', fontSize: '1.5rem', color: '#fff' }}
                              >
                                <i class="fa-solid fa-xmark fa-2x" style={{ color: "white" }}></i>
                              </button>
                              <div
                                className="background-image"
                                style={{
                                  backgroundImage: `url(${item.image})`,
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  filter: "blur(40px)",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  zIndex: 1
                                }}
                              ></div>
                              <img
                                className="original-image"
                                src={item.image}
                                alt="User"
                                style={{
                                  height: "65vh",
                                  position: "relative",
                                  zIndex: 2
                                }}
                                onError={(event) => this.handleImageError(event, item.image)}
                              />
                            </div>
                            <div class="blur-effect"></div>
                            <div class="shade-effect "></div>


                          </div>
                        </div>
                        <h3 className='text-white px-3 py-1'>@ {item.creator}</h3>
                        <p className='text-white px-3'>{item.caption}</p>
                        <div className="p-3" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20%" }}>
                          <div className="card bg-success" style={{ borderRadius: "100%" }} onClick={() => this.handleReject(item.id)}>
                            <div className="card-body bg-danger text-center " style={{ borderRadius: "100%", border: "white solid 3px" }}>
                              <i class="fa-solid fa-xmark fa-3x" style={{ color: "#120c34" }}></i>
                            </div>
                          </div>
                          <div className="card bg-success" style={{ borderRadius: "100%" }} onClick={() => this.handleApprove(item.id)} >
                            <div className="card-body bg-sucess text-center " style={{ borderRadius: "100%", border: "white solid 3px" }}>
                              <i class="fa-solid fa-check fa-3x" style={{ color: "#120c34" }} ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer />
      </div>

    )
  }
}
