import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            path: '/',
            isOpen: false,
            balance: 0,
            User_image: "",
            windowWidth: window.innerWidth
        };
    }
    toggleSidebar = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    fetchData = async () => {
        const token = localStorage.getItem("accessToken");
        console.log("result:  ")

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'SUCCESS') {
                    this.setState({
                        data: result.data,
                        User_image: result.data.images,
                    });
                    console.log("result:  ", result.data)
                } else if (result.code === 'token_not_valid') {
                    window.localStorage.removeItem("refreshToken")
                    window.localStorage.removeItem("accessToken")
                    window.localStorage.removeItem("userName")
                    window.localStorage.removeItem("isLogedin")
                    console.error('Token is invalid or expired');
                    window.location.href = '/signin';
                } else {
                    throw new Error('Failed to fetch data');
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            this.setState({ error: error.message });
            console.error(error.message);
        }
    };
    fetchDashboardData = async () => {
        const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
        const headers = new Headers({
            'Authorization': `Bearer ${token}` // Set the Authorization header with the bearer token
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/dashboard`, {
                method: 'GET',
                headers: headers
            });
            const result = await response.json();

            if (response.ok && result.status === 'SUCCESS') {
                // Update the state with data from the response
                this.setState({
                    balance: result.data.user.balance,
                    error: null
                });
            } else {
                if (result.code === 'token_not_valid') {
                    window.localStorage.removeItem("refreshToken")
                    window.localStorage.removeItem("accessToken")
                    window.localStorage.removeItem("userName")
                    window.localStorage.removeItem("isLogedin")
                    window.localStorage.removeItem("refreshToken")
                    window.localStorage.removeItem("accessToken")
                    window.localStorage.removeItem("userName")
                    window.localStorage.removeItem("isLogedin")
                    window.location.href = '/signin'; // Automatically redirect to /signin
                } else {
                    throw new Error('Failed to fetch data');
                }
            }
        } catch (error) {
            this.setState({ error: error.message });
            console.error(error.message);
        }
    };

    componentDidMount() {
        console.log('Component did mount changed');
        window.addEventListener('resize', this.handleResize);
        const isLogedin = localStorage.getItem("isLogedin") === 'true';
        if (isLogedin) {
            this.fetchData();
            this.fetchDashboardData();
        }
        this.determinePath();
    }

    componentWillUnmount() {
        // console.log('Component did mount');
        // const isLogedin = localStorage.getItem("isLogedin") === 'true';
        // if (isLogedin) {
        //     this.fetchData();
        //     this.fetchDashboardData();
        // }
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }
    signOut = () => {
        localStorage.clear();
        window.location.reload();
    }
    isLogedin = () => {
        return window.localStorage.getItem("isLogedin"); // Convert string to boolean
    }

    

    determinePath = () => {
        const accountType = window.localStorage.getItem("account");
        if (!accountType) {
            this.setState({ path: '/signin' });
        } else if (accountType === '1') {
            this.setState({ path: '/home' });
        } else if (accountType === '2') {
            this.setState({ path: '/' });
        }
    }
    determineCreator = () => {
        const accountType = window.localStorage.getItem("account");
        if (accountType === '1') {
            return true;
        } else if (accountType === '2') {
            return false;
        }
    }
    handleDropdownClick = (event) => {
        if (!this.props.isLogedin) {
            event.preventDefault();
        }
    }
    handleNavLinkClick = () => {
        const nav = document.getElementById('navbarNav');
        if (nav.classList.contains('show')) {
            nav.classList.remove('show');
        }
    };

    render() {
        // const { text } = this.props;
        const { text, isLogedin, textColor: originalTextColor, photo } = this.props;
        const { data } = this.state;
        const { isOpen } = this.state;
        console.log("IMAGE : ", data.images);

        const { windowWidth } = this.state;
        const textColor = originalTextColor || '#120c34';
        const linkStyle = (isActive) => ({
            fontWeight: isActive ? 'bold' : '500',
            fontSize: 'x-large',
            color: isActive ? 'white' : textColor,
            backgroundColor: isActive ? '#120c34' : 'transparent',
            borderRadius: isActive ? '25px' : 'transparent',
        });
        const linkClass = ({ isActive }) => isActive ? 'btn btn-lg active' : 'btn btn-lg';
        if (this.determineCreator() === true) {
            return (
                <div>
                    <nav className="navbar navbar-expand-lg navbar-light" style={{ background: text, borderBottom: "grey 4px solid" }}>
                        <Link to={this.state.path} className="navbar-brand">
                            <img src={`/images/${photo || "embassy_logo.png"}`} alt="" style={{ width: 'auto', height: '50px' }} />
                        </Link>

                        <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            {windowWidth < 768 ? (
                                <span className="navbar-toggler-icon border-0 " style={{ backgroundImage: 'url(/images/person.png)' }}></span>
                            ) : (
                                <span className="navbar-toggler-icon"></span>
                            )}
                        </button>


                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {isLogedin && (
                                    <>
                                        {/* <li className="nav-item mr-5">
                                            <Link type="button" className="btn btn-lg" style={({ isActive }) => linkStyle(isActive)} to="/home">Dashboard</Link>
                                        </li>
                                        <li className="nav-item active mr-5">
                                            <Link type="button" className="btn btn-lg" style={({ isActive }) => linkStyle(isActive)} to="/home/all_campaign">Campaigns</Link>
                                        </li>
                                        <li className="nav-item mr-5">
                                            <Link type="button" className="btn btn-lg" style={({ isActive }) => linkStyle(isActive)} to="/home/marketplace_request">Marketplace Requests</Link>
                                        </li> */}
                                        <li className="nav-item mr-5">
                                            <NavLink

                                                type="button"
                                                className="btn btn-lg"
                                                style={{ fontWeight: "500", fontSize: "x-large", color: textColor }}
                                                to="/home"
                                            >
                                                Dashboard
                                            </NavLink>
                                        </li>
                                        <li className="nav-item mr-5">
                                            <NavLink
                                            exact
                                                type="button"
                                                className="btn btn-lg"
                                                style={({ isActive }) => linkStyle(isActive)}
                                                to="/home/all_campaign"
                                            >
                                                Campaigns
                                            </NavLink>
                                        </li>
                                        <li className="nav-item mr-5">
                                            <NavLink
                                                exact
                                                type="button"
                                                className="btn btn-lg"
                                                style={({ isActive }) => linkStyle(isActive)}
                                                to="/home/marketplace_request"
                                            >
                                                Marketplace Requests
                                            </NavLink>
                                        </li>
                                    </>
                                )}

                                {windowWidth < 768 && isLogedin ? (
                                    <>
                                        <div class="dropdown-divider"></div>
                                        <li className="nav-item mr-5">
                                            <Link className="btn btn-lg" style={{ fontWeight: "500", color: textColor }} onClick={this.signOut}>Signout</Link>
                                        </li>
                                    </>
                                ) : (
                                    <li className="nav-item dropdown">
                                        {isLogedin ? (
                                            <Link className="btn btn-lg" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {/* <i className="fa-regular fa-circle-user  hide" style={{ fontWeight: "500", color: textColor, fontSize: "xx-large" }}></i> */}
                                                <img className='img-fluid ' src={data.images} alt="" style={{ width: "40px", height: "40px", borderRadius: "50%", border: "#120c34 solid 1px", objectFit: "cover", }} />
                                            </Link>
                                        ) : (
                                            <Link className="btn btn-lg" id="navbarDropdown" >
                                                <i className="fa-regular fa-circle-user  hide" style={{ fontWeight: "500", color: textColor, fontSize: "xx-large" }}></i>
                                            </Link>
                                        )}
                                        <div className="dropdown-menu dropdown-menu-right p-3" style={{ borderTopLeftRadius: "25px", borderBottomRightRadius: "25px", borderBottomLeftRadius: "25px", }} aria-labelledby="navbarDropdown">
                                            {isLogedin ? (
                                                <>
                                                    <Link className="dropdown-item " to="/profile/dashboard" style={{ fontWeight: "bold" }}>Edit</Link>
                                                    <div className="dropdown-divider"></div>
                                                    <Link className="dropdown-item" onClick={this.signOut} style={{ fontWeight: "bold" }}>Signout</Link>
                                                </>
                                            ) : (
                                                <Link className="dropdown-item" to="/signup">Signup</Link>
                                                // /profile/dashboard

                                            )}
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </nav>
                </div>
            );
        } else {
            return (
                <div>
                    <nav className="navbar navbar-expand-lg navbar-light" style={{ background: text, borderBottom: "grey 4px solid" }}>
                        <Link to={this.state.path} className="navbar-brand">
                            <img src={`/images/${photo || "embassy_logo.png"}`} alt="" style={{ width: 'auto', height: '50px' }} />
                        </Link>
                        {/* <Link to={this.state.path} className="navbar-brand"><img src="/images/embassy_logo.png" alt="" style={{ width: 'auto', height: '50px' }} /></Link> */}
                        <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            {windowWidth < 768 ? (
                                <span className="navbar-toggler-icon border-0 " style={{ backgroundImage: 'url(/images/person.png)' }}></span>
                            ) : (
                                <span className="navbar-toggler-icon"></span>
                            )}
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto ">
                                {isLogedin && (
                                    // <>
                                    //     <li className="nav-item mr-5">
                                    //         {/* <a type="button" className="btn btn-lg" href="#" style={{ fontWeight: "500", fontSize: "x-large", color: textColor }}>Dashboard</a> */}
                                    //         <Link type="button" className="btn btn-lg" href="#" style={{ fontWeight: "500", fontSize: "x-large", color: textColor }} to="">Dashboard</Link>

                                    //     </li>
                                    //     <li className="nav-item active mr-5" >
                                    //         {/* <a type="button" className="btn btn-lg" href="#" style={{fontWeight:"500",fontSize:"x-large"}}>Campaigns</a> */}
                                    //         <Link type="button" className="btn btn-lg" href="#" style={{ fontWeight: "500", fontSize: "x-large", color: textColor }} to="/campaigns_dashboard">Campaigns</Link>
                                    //     </li>
                                    //     <li className="nav-item mr-5">
                                    //         {/* <a type="button" className="btn btn-lg" href="#" style={{fontWeight:"500",fontSize:"x-large"}}>Marketplace</a> */}
                                    //         <Link type="button" className="btn btn-lg" href="#" style={{ fontWeight: "500", fontSize: "x-large", color: textColor }} to="/marketplace">Marketplace</Link>
                                    //     </li>
                                    // </>
                                    <>
                                        <li className="nav-item mr-md-5">
                                            <NavLink
                                                exact
                                                type="button"
                                                className={linkClass}
                                                style={({ isActive }) => linkStyle(isActive)}
                                                to="/"

                                                activeStyle={{ fontWeight: "bold", backgroundColor: "#120c34", color: "white" }}
                                            >
                                                Dashboard
                                            </NavLink>
                                        </li>
                                        <li className="nav-item active mr-md-5">
                                            <NavLink
                                                type="button"
                                                className={linkClass}
                                                style={({ isActive }) => linkStyle(isActive)}
                                                to="/campaigns_dashboard"
                                                activeStyle={{ fontWeight: "bold", backgroundColor: "#120c34", color: "white" }}
                                            >
                                                Campaigns
                                            </NavLink>
                                        </li>
                                        <li className="nav-item mr-md-5">
                                            <NavLink
                                                type="button"
                                                className={linkClass}
                                                style={({ isActive }) => linkStyle(isActive)}
                                                to="/marketplace"
                                                activeStyle={{ fontWeight: "bold", backgroundColor: "#120c34", color: "white" }}
                                            >
                                                Marketplace
                                            </NavLink>
                                        </li>
                                    </>
                                )}

                                {windowWidth < 768 && isLogedin ? (
                                    <>
                                        <div class="dropdown-divider"></div>
                                        <li className="nav-item mr-md-5">
                                            <Link className="btn btn-lg" to="/profile/dashboard" style={{ fontWeight: "500", color: textColor }}><u>Edit</u></Link>
                                            <Link className="btn btn-lg" style={{ fontWeight: "500", color: textColor }} onClick={this.signOut}><u>Signout</u></Link>
                                        </li>
                                    </>
                                ) : (
                                    <li className="nav-item dropdown">
                                        {isLogedin && windowWidth > 768 ? (
                                            // <Link className="btn btn-lg" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            //     <i className="fa-regular fa-circle-user  hide" style={{ fontWeight: "500", color: textColor, fontSize: "xx-large" }}></i>
                                            //     <img className='img-fluid ' src={data.images} alt="" style={{ width: "42px", height: "42px", borderRadius: "50%", border: "#120c34 solid 1px", objectFit: "contain", }} />
                                            // </Link>
                                            <button className="btn btn-lg bg-transparent" onClick={this.toggleSidebar}
                                            >

                                                {/* <i className="fa-regular fa-circle-user  hide" style={{ fontWeight: "500", color: textColor, fontSize: "xx-large" }}></i> */}
                                                <img className='img-fluid ' src={data.images} alt="" style={{ width: "42px", height: "42px", borderRadius: "50%", border: "#120c34 solid 1px", objectFit: "contain", }} />
                                            </button>
                                        ) : (
                                            <Link className="btn btn-lg" id="navbarDropdown" >
                                                <i className="fa-regular fa-circle-user  hide" style={{ fontWeight: "500", color: textColor, fontSize: "xx-large" }}></i>
                                            </Link>
                                        )}

                                        <div className="dropdown-menu dropdown-menu-right p-3" style={{ borderTopLeftRadius: "25px", borderBottomRightRadius: "25px", borderBottomLeftRadius: "25px", }} aria-labelledby="navbarDropdown">
                                            {isLogedin ? (
                                                <>
                                                    <Link className="dropdown-item " to="/profile/dashboard" style={{ fontWeight: "bold" }}>Edit</Link>
                                                    <div className="dropdown-divider"></div>
                                                    <Link className="dropdown-item" onClick={this.signOut} style={{ fontWeight: "bold" }}>Signout</Link>
                                                </>
                                            ) : (
                                                <Link className="dropdown-item" to="/signup">Signup</Link>
                                                // /profile/dashboard

                                            )}
                                        </div>
                                    </li>

                                )}
                                <div
                                    className="position-fixed h-100"
                                    style={{
                                        top: '0',
                                        right: '0',
                                        width: '30%',
                                        zIndex: 999,
                                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                        transform: isOpen ? 'translateX(0) rotateY(0deg)' : 'translateX(100%) rotateY(-15deg) scale(0.95)',
                                        backgroundColor: 'white',
                                        boxShadow: isOpen ? '0 20px 40px rgba(18, 12, 52, 0.5)' : '0 10px 20px rgba(18, 12, 52, 0.3)',
                                        transformOrigin: 'right center',
                                        perspective: '1200px',
                                        color: 'black',
                                        borderTopLeftRadius: "25px",
                                        borderBottomLeftRadius: "25px",
                                    }}
                                >
                                    <Link
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            onClick={this.toggleSidebar}
                                            style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '25px',
                                                background: 'transparent',
                                                border: 'none',
                                                fontSize: '2.5rem',
                                                cursor: 'pointer',
                                                color:textColor
                                            }}
                                        >
                                            &times;
                                        </Link>
                                    <div className="card-body " style={{ borderTopLeftRadius: "25px", height: "90vh" }}>
                                        
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <Link
                                            to="/profile/dashboard"
                                                type="button"
                                                className="btn-close btn-close-white bg-transparent border-0"
                                                aria-label="Close"
                                                onClick={this.toggleSidebar}
                                            >
                                                {/* <i className="fa-regular fa-circle-user fa-4x  hide" style={{ fontWeight: "500", color: textColor }}></i> */}
                                                <img className='img-fluid ' src={this.state.User_image} alt="" style={{ width: "70px", height: "70px", borderRadius: "50%", border: "#120c34 solid 1px", objectFit: "contain", }} />
                                            </Link>
                                            <Link to="/profile/dashboard">
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <p style={{ color: textColor, fontSize: "1.5rem", margin: "0" }}>{data.name}</p>
                                                <p style={{ color: textColor, fontSize: "1.2rem", margin: "0" }}>{data.email}</p>
                                            </div>
                                            </Link>

                                        </div>
                                        <div className="card p-0 mt-2" style={{ backgroundColor: '#3898ec', backgroundColor: textColor, borderRadius: "25px" }}>
                                            <div>
                                                <div className="row " style={{ display: "flex", alignItems: "center" }}>
                                                    <div className="col-6 col-md-6   col-sm-12 text-white">
                                                        <h4 className='m-2' style={{ fontWeight: "600" }}>${this.state.balance}</h4>
                                                        <h6 className='m-2' style={{ fontWeight: "800" }}>Current Balance</h6>
                                                    </div>
                                                    <div className="col-6 col-md-6 col-sm-12 p-4 d-flex align-items-center justify-content-center">
                                                        <button type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} data-toggle="modal" data-target="#exampleModalCenter" >Manage</button>
                                                        {/* <Link type="button" className="btn btn-light btn-lg mr-3" style={{ borderRadius: "25px" }} to="/make-payment">Manage</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-12 mt-4">
                                                <Link to="#">
                                                    <h4 style={{ color: textColor }}>Manage Payment Method</h4>
                                                </Link>

                                            </div>
                                            <div className="col-12 mt-2">
                                                <Link to="#">
                                                    <h4 style={{ color: textColor }}>Manage Subscription</h4>

                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer border-0 text-center  bg-white">
                                        <div className="row text-center" style={{ marginTop: 'auto', padding: '10px' }}>
                                            <div className="col-12">
                                                <h4 style={{ color: textColor }}>Not you? Log out <Link to="#" style={{ color: textColor }} onClick={this.signOut}><u>here</u></Link></h4>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </ul>
                        </div>
                    </nav>
                </div>
            );
        }
    }
}
