import React, { Component, useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';
import Animation_Custom from '../AnimationCustom/Animation_Custom';

// import Apiloading_animation from '../Animation/Apiloading_animation';


export default class Dashboard extends Component {


  constructor(props) {
    super(props);
    this.state = {
      data: [],
      countries: [],
      loading: true,
      min_age: '',
      max_age: '',
      gender: '',
      followers_min: '',
      followers_max: '',
      interests: '',
      // Upper variables arre for filter
      isLoading: true,

      selected_creators: 0,
      isActive: false,
      showForm: false,
      currentStep: 1,
      page: 1,

      userID: '',
      startDate: '',
      requestType: '',
      Platform: '',
      requestInstructionsText: '',
      WebsiteUrl: '',
      file: null,
      countInput: "", // in-feed photo post
      countInput1: "", // 15-30 second reel
      countInput2: "", // 3-frame photo story
      countInput3: "", // 3-frame video story
      postFrequencyCount: "1 Post", // Post Frequency select option
      postFrequencyPer: "week", // Post Frequency select option
      offerAmount: "",
      productIncluded: "",
      productURL: "",
      productValue: '',
      count: 0
    };
  }

  fetchCountries = async () => {
    try {
      // console.log("Base URL:", process.env.REACT_APP_BASE_URL);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/state`);
      const json = await response.json();
      if (json.status === 'SUCCESS') {
        this.setState({ countries: json.data, loading: false });
        // console.log("countries: ", this.state.countries)
      } else {
        this.setState({ error: 'Failed to fetch countries', loading: false });
      }
    } catch (err) {
      this.setState({ error: err.message, loading: false });
    }
  }

  renderCountriesOptions = () => {
    return this.state.countries.map((country) => (
      <option key={country.id} value={country.id}>
        {country.name} / {country.subcountry}
      </option>
    ));
  }


  render() {
    const { error, loading, countries } = this.state;
    const { selectedCountry, onCountryChange } = this.props;

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <select className="custom-select" style={{ borderRadius: "25px" }} value={selectedCountry} onChange={onCountryChange}>
        {countries.map(country => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>
    );
  }

  handleExpInput = (event) => {
    let input = event.target.value.replace(/[^\d]/g, ''); // Remove non-digits

    // Automatically add slashes
    if (input.length > 2 && input.length <= 4) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    }
    if (input.length > 4) {
      input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
    }

    // Validate the input before returning
    if (/^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(input)) {
      return input; // Return the formatted date
    }
    return event.target.value; // Return original input if it doesn't match the format
  };


  onDate = (event) => {
    // this.handleExpInput(event); // Formats the date
    // this.handleInputFormChange('startDate', event.target.value, event); // Handles additional logic
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputFormChange('startDate', formattedDate);
  };

  convertDate = (inputDate) => {
    // Parse the date from MM/DD/YYYY format
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1; // Adjust month from 1-12 to 0-11 for JS Date
    const day = parseInt(dateParts[1], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as YYYY-MM-DDT00:00:00.000000Z
    const isoString = date.toISOString(); // Returns YYYY-MM-DDTHH:MM:SS.sssZ
    const startDatetime = isoString.split('T')[0] + 'T00:00:00.000000Z'; // Restrict time to 00:00:00.000000Z

    // Example to show how to use it in state, if needed
    // this.setState({ start_datetime: startDatetime });

    return startDatetime;
  };

  truncateAddress(address, wordLimit) {
    const parts = address.split(',');
    if (parts.length === 2) {
      return parts[1].trim();
    }
    const truncatedParts = parts.slice(-2);
    let result = truncatedParts.join(', ').trim();

    result = result.replace(/\b\d{5}(?:-\d{4})?\b/g, '').trim();

    return result;
  }

  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  }

  componentDidMount() {
    this.fetchData(this.state.page);
    this.fetchCountries();
  }


  fetchData = async (page) => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("accessToken");
    const headers = new Headers({
      'Authorization': `Bearer ${token}`
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/marketplace?page=${page}&page_size=15`, {
        method: 'GET',
        headers: headers
      });
      const result = await response.json();

      if (response.ok && result.status === 'SUCCESS') {
        this.setState(prevState => ({
          data: page === 1 ? result.data.results : [...prevState.data, ...result.data.results], // Only append if not page 1
          isLoading: false,
          // Do not increment page here
        }));
      } else {
        if (result.code === 'token_not_valid') {
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
          console.error('Token is invalid or expired');
          window.location.href = '/signin';
        } else {
          throw new Error('Failed to fetch data');
        }
      }
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
      console.error(error.message);
    }
  };

  loadMore = () => {
    const nextPage = this.state.page + 1; // Calculate the next page
    this.fetchData(nextPage); // Fetch data for the next page
    this.setState({ page: nextPage }); // Then increment the page state
  }

  formatNumber(number) {
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + 'B';
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + 'M';
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + 'K';
    } else {
      return number.toString();
    }
  }


  filterData = async () => {
    let { min_age, max_age, gender, followers_min, followers_max, interests } = this.state;
    if (gender === 'Male') {
      gender = '1';
    } else if (gender === 'Female') {
      gender = '2';
    }

    // Check if any filter parameters are set
    if (!min_age && !max_age && !gender && !followers_min && !followers_max && !interests) {
      // If no filters are set, fetch all data
      this.fetchData();
      return;
    }

    const token = localStorage.getItem("accessToken");
    const headers = new Headers({
      'Authorization': `Bearer ${token}`
    });

    const queryParams = new URLSearchParams();

    if (min_age) queryParams.append('min_age', min_age);
    if (max_age) queryParams.append('max_age', max_age);
    if (gender) queryParams.append('gender', gender);
    if (followers_min) queryParams.append('followers_min', followers_min);
    if (followers_max) queryParams.append('followers_max', followers_max);
    if (interests) queryParams.append('interests', interests);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/marketplace?${queryParams.toString()}&page_size=15`, {
        method: 'GET',
        headers: headers
      });
      const result = await response.json();

      if (response.ok && result.status === 'SUCCESS') {
        this.setState({ data: result.data.results });
      } else {
        if (result.code === 'token_not_valid') {
            window.localStorage.removeItem("refreshToken")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("userName")
            window.localStorage.removeItem("isLogedin")
          console.error('Token is invalid or expired');
          window.location.href = '/signin';
        } else {
          throw new Error('Failed to fetch data');
        }
      }
    } catch (error) {
      this.setState({ error: error.message });
      console.error(error.message);
    }
  };

  handleDateSelect = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ startDate: formattedDate });
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ startDate: date });
    this.handleInputFormChange('startDate', formattedDate);
  };
  handleExpInput = (event) => {
    const date = new Date(event.target.value);
    return format(date, 'MM/dd/yyyy');
  };

  handleInputChange = (field, value) => {
    console.log(`Debug: ${field} changed to ${value}`);
    
    this.setState({ [field]: value }, () => {
      console.log(`Debug: State updated, ${field} is now ${this.state[field]}`);
      this.filterData();
    });
  };


  renderCards() {
    const { data } = this.state;
    return data.map(user => (
      <div className="col-md-4 col-sm-12 mb-3" key={user.id} data-toggle="modal" data-target={`#modal-${user.id}`} style={{ cursor: "pointer" }} >
        <div className="card card-big border-0" style={{ background: "#120c34", color: "white", borderRadius: "25px", width: "100%", height: "auto" }}>
          <div style={{ position: 'relative' }}>
            <div className="text-center" style={{ position: "relative",borderTopLeftRadius: "25px",borderTopRightRadius: "25px", overflow: "hidden", width: "100%", paddingBottom: "100%" }}>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${user.images ? user.images[0] : "/images/person.png"})`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "25px",
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1
                }}
              ></div>
              <div className='text-center' style={{display:"flex",alignItems:"center", overflow: "hidden",}}>
              <img
                className="original-image"
                src={user.images ? user.images[0] : "/images/person.png"}
                alt="User"
                style={{
                  width:"100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  // width:"100%",
                  position: "absolute",
                  top: 0,
                  // left: 0,
                  zIndex: 2,
                  objectFit: "cover",
                  objectPosition: "center"
                }}
              />
              </div>
              {user.favorite ? (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onClickSelectedCreators(event, user.id);
                    this.setState(prevState => ({
                      data: prevState.data.map(item => 
                        item.id === user.id ? {...item, favorite: !item.favorite} : item
                      )
                    }));
                  }}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="red"
                    style={{
                      width: "48px",
                      height: "48px"
                    }}
                  >
                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                  </svg>
                </a>
              ) : (
                <a
                  className="bg-transparent border-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onClickSelectedCreators(event, user.id);
                    this.setState(prevState => ({
                      data: prevState.data.map(item => 
                        item.id === user.id ? {...item, favorite: !item.favorite} : item
                      )
                    }));
                  }}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "20px",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    zIndex: 3
                  }}
                >
                  <i className="fa-regular fa-heart fa-3x" style={{ color: "white" }}></i>
                </a>
              )}

            </div>

            <div class="blur-effect"></div>
            <div class="shade-effect "></div>
          </div>
          <div>
            <div className="card-body1 px-3 pt-2">
              <p className="card-text alignleft font-type" style={{ fontWeight: "800" }}>{user.name}</p>
              <small className="card-text alignright">{user.location ? this.truncateAddress(user.location, 5) : "No location"} <i className="fa-solid fa-location-dot"></i></small>
            </div>
            <div className="card-body2 px-3 d-grid">
              {user.social && user.social.instagram && user.social.instagram !== 0 && (
                <>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <img src="/images/instagram.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                  <p>{this.formatNumber(user.social.instagram)}</p>
                </>
              )}
              <br />
              {user.social && user.social.tiktok && user.social.tiktok !== 0 && (
                <>
                  <a href="#" style={{ textDecoration: 'none' }}>
                    <img src="/images/tiktok.png" alt="" style={{ width: "30px", height: "30px" }} />
                  </a>
                  <p>{this.formatNumber(user.social.tiktok)}</p>
                </>
              )}
              <br />
            </div>
            <div className="d-grid px-3 mb-2">
              {user.company ? (
                <button className='small-rounded-button mr-1 px-1'><small>{user.company}</small></button>
              ) : (
                <>
                  <button className='small-rounded-button mr-1 px-1'><small>Fitness</small></button>
                  <button className='small-rounded-button mr-1 px-1'><small>FootBall</small></button>
                  <button className='small-rounded-button mr-1 px-1'><small>Dance</small></button>
                </>
              )}
              <small>+2 more</small>
            </div>
          </div>
          <div className="modal fade bd-example-modal-lg p-0" id={`modal-${user.id}`} tabIndex="-1" role="dialog"
            aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ borderRadius: "25px" }}>
            <div className="modal-dialog modal-lg" role="document" style={{ color: "white", borderRadius: "25px", maxWidth: "700px" }}>
              <div className="modal-content" style={{ borderRadius: "25px" }}>
                <div className="modal-body p-0" onClick={e => this.handleInputFormChange1('userID', user.id, e)} style={{ borderRadius: "25px" }}>
                  {this.renderModalContent(user)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    ));
  }


  isLogedin = () => {
    return window.localStorage.getItem("isLogedin"); // Convert string to boolean
  }


  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  nextStep = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  };


  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState(prevState => ({ currentStep: prevState.currentStep - 1 }));
    }
  };

  handleInputFormChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]);
    });
  }
  handleInputFormChange1 = (key, value, e) => {
    e.stopPropagation();
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
    });
  }


  filterData() {
    const { data, min, max } = this.state;
    const minValue = Number(min);
    const maxValue = Number(max);

    const filteredData = data.filter(user => {
      // Assuming 'propertyToFilterBy' is the property you want to filter on
      const propertyValue = Number(user.propertyToFilterBy);
      return (!minValue || propertyValue >= minValue) && (!maxValue || propertyValue <= maxValue);
    });

    this.setState({ filteredData });
  }

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value
    }), () => {
      // console.log(`Updated ${field}:`, this.state[field]);
      this.filterData();
      // console.log(this.filterData())
    });
  };



  handleToggleBTN = () => {
    if (this.state.isActive == true) {
      this.setState({ isActive: false });
    }
    else {
      this.setState({ isActive: true });
    }
  }
  onClickSelectedCreators = (e) => {
    this.setState(prevState => ({
      selected_creators: e.target.checked ? prevState.selected_creators + 1 : prevState.selected_creators - 1
    }), () => {
      // console.log(this.state.selected_creators); // This logs the updated state value
    });
  }


  onClickSelectedCreators = async (event, userId) => {
    event.stopPropagation();

    // Update the state with the new selected_creators count
    this.setState(prevState => ({
      selected_creators: event.target.checked ? prevState.selected_creators + 1 : prevState.selected_creators - 1
    }), () => {
      // console.log(this.state.selected_creators); // This logs the updated state value

      // Proceed with the API call after state update
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error('Access token not found');
        return;
      }

      const url = `${process.env.REACT_APP_BASE_URL}api/general/v1/favorite`;
      const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const body = JSON.stringify({ creator: userId });

      // Call the API
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
          }
          return response.json(); // Parse the JSON response
        })
        .then(data => {
          // console.log(data); // Handle successful response
          if (data.message === "Added to favorite!") {
            toast.success(data.message);
          } else {
            toast.warning(data.message);
          }
        })
        .catch(error => {
          console.error('Error calling API:', error);
        });
    });
  };



  handleSubmit = async (event) => {
    event.preventDefault();  // Prevent form from submitting traditionally
    const token = localStorage.getItem("accessToken");
    const campaignTypeLabels = {
      1: 'UGC',
      2: 'Posted Content',
      3: 'Growth',
      4: 'Content'
    };
    const requestBody = {
      title: "Market Place Request",
      // creator: parseInt(this.state.userID), // Assuming static for the example
      brand_id: 1, // Assuming static for the example
      start_datetime: this.convertDate(this.state.startDate),
      campaign_type: this.state.contentGoal == "UGC" ? 1 : 2, // Assuming contentGoal corresponds to campaign_type
      budget: parseInt(this.state.offerAmount), // Parse the budget to an integer
      creator_id: this.state.userID,
      details: {
        creator_id: this.state.userID,
        platform: this.state.Platform,
        description: this.state.requestInstructionsText,
        url: this.state.WebsiteUrl, // Assuming url corresponds to WebsiteUrl in state
        attachments: [], // Assuming no attachments
        photo_post: parseInt(this.state.countInput), // Conversion to integer from countInput
        reel: parseInt(this.state.countInput1), // Conversion to integer from countInput1
        photo_story: parseInt(this.state.countInput2), // Conversion to integer from countInput2
        video_story: parseInt(this.state.countInput3), // Conversion to integer from countInput3
        post_frequency: parseInt(this.state.postFrequencyCount.split(' ')[0]), // Extract number from postFrequencyCount
        post_schedule: this.state.postFrequencyPer, // Corresponds to postFrequencyPer in state
        product_included: parseInt(this.state.productIncluded), // Assuming this should be an integer
        product_url: this.state.productURL, // Matches productURL in state
        product_value: parseFloat(this.state.productValue) // Assuming this should be a float
      }
    };
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Include the Bearer token in the header
      },
      body: JSON.stringify(requestBody)
    };

    // Make the API request
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign`, fetchOptions);
      const responseData = await response.json(); // Assuming the server responds with JSON
      // console.log('API Response:', responseData);

      // Handle response data or errors here
      if (response.ok) {
        // Successful POST request (e.g., status code 200-299)
        // console.log('Campaign created successfully:', responseData);
        window.location.href = '/manage_req';
      } else {
        // Server responded with a status code outside the 200 range (e.g., error)
        console.error('Failed to create campaign:', responseData);
      }
    } catch (error) {
      // An error occurred during the fetch operation
      console.error('Error during fetch:', error);
    }

  };


  renderForm() {
    const { currentStep, startDate, requestType, selectedLogo, requestInstructions, url, file, count } = this.state;
    switch (currentStep) {
      case 1:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar" >
              <li className="active" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li id="step2"><strong></strong></li>
              <li id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            {/* <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required /> */}
            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Start Date</h3>
            {/* <input type="text" onChange={this.onDate} onClick={(e) => e.stopPropagation()} name="startDate" className="input-box text-center rounded-pill mx-3 " style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} value={this.state.startDate} placeholder="MM/DD/YYYY" /> */}
            <DatePicker
              selected={this.state.startDate ? format(this.state.startDate, 'MM/dd/yyyy', new Date()) : null}
              onSelect={this.handleDateSelect}
              onChange={this.handleDateChange}
              // isClearable
              dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
              placeholderText="mm/dd/yyyy"
              minDate={new Date()}
              customInput={
                <input
                  type="text"
                  onChange={this.handleInputChange}
                  onClick={(e) => e.stopPropagation()}
                  name="startDate"
                  className="input-box text-center rounded-pill mx-3"
                  style={{
                    fontWeight: '800',
                    fontSize: 'x-large',
                    backgroundColor: 'white',
                    width: '60%',
                    padding: '15px',
                    borderRadius: '25px',
                    color: '#120c34',
                  }}
                  value={this.state.startDate ? format(this.state.startDate, 'MM/dd/yyyy') : ''}
                  placeholder="MM/DD/YYYY"
                />
              }
            />
            {/* <input type="text" onChange={e => this.handleInputChange('showDate', e.target.value)} onClick={(e) => e.stopPropagation()} name="startDate" className="input-box text-center rounded-pill mx-3" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="xx/xx/xxx" /> */}

            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Type</h3>
            <div className="row mt-3">
              <div className="col-6 col-sm-6">
                <input onClick={e => this.handleInputFormChange1('requestType', e.target.value, e)} type="button" className=" rounded-pill mx-3 selected" style={{ color: this.state.requestType === 'Posted Content' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.requestType === 'Posted Content' ? 'white' : 'transparent', width: '60%', padding: '15px', borderRadius: "25px", }} value="Posted Content" />
              </div>
              <div className="col-6 col-sm-6">
                <input onClick={e => this.handleInputFormChange1('requestType', e.target.value, e)} type="button" className=" rounded-pill mx-3 " style={{ color: this.state.requestType === 'UGC' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.requestType === 'UGC' ? 'white' : 'transparent', width: '50%', padding: '15px', borderRadius: "25px" }} value="UGC" />
              </div>
            </div>

            <div className="text-center  ">
              <h3 className='mt-5' style={{ fontWeight: "800" }}>Platform</h3>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo1">
                      <img src="/images/instagram.png" onClick={(e) => e.stopPropagation()} alt="Instagram Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onChange={e => this.handleInputFormChange1('Platform', e.target.value, e)} onClick={(e) => e.stopPropagation()} id="logo1" name="logo" value="instagram" />

                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo2">
                      <img src="/images/tiktok.png" onClick={(e) => e.stopPropagation()} alt="TikTok Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputFormChange('Platform', e.target.value)} id="logo2" name="logo" value="tiktok" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 col-sm-4 col-xs-12 text-center">
                    <label htmlFor="logo3">
                      <img src="/images/youtube.png" onClick={(e) => e.stopPropagation()} alt="YouTube Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputFormChange('Platform', e.target.value)} id="logo3" name="logo" value="youtube" />
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Cancel" />
              </div>
              <div className="col-6 col-sm-6">
                <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Continue
                  </span>
                </button>

              </div>
            </div>

          </div>
        );
      case 2:
        const maxLength = 400;
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar" >
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='active' id="step2"><strong></strong></li>
              <li id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}>Request Instruction</h3>

            <div className=" mx-3">
              <div className=" position-relative" style={{ marginBottom: '20px' }}>
                <textarea
                  onClick={(e) => e.stopPropagation()}
                  onChange={e => this.handleInputFormChange('requestInstructionsText', e.target.value)}
                  className="form-control"
                  style={{
                    height: '20em',
                    borderRadius: '25px',
                    resize: 'none'
                  }}
                  placeholder="Tell the creators what you'd like to post"
                  maxLength={maxLength}
                />
                <div className="position-absolute" style={{
                  bottom: '5px',
                  right: '10px',
                  fontSize: '12px',
                  color: 'gray'
                }}>
                  {/* Placeholder text, since we're not managing state here */}
                  <b style={{ color: "#120c34", }}>{`${maxLength}/${maxLength} characters remaining`}</b>
                </div>
              </div>
            </div>

            <div className="card mx-3 mt-2 px-2" style={{ borderRadius: '25px', }}>
              <div className="d-flex align-items-center">
                <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4>
                <input onClick={(e) => e.stopPropagation()} onChange={e => this.handleInputFormChange('WebsiteUrl', e.target.value)} className='border  border-0 form-control' style={{ border: '0', borderRadius: '25px' }} type="text" placeholder="www.yourwebiste.com" aria-label="Username" aria-describedby="basic-addon1" />
              </div>
            </div>

            <div className="card mx-3 mt-3 px-2" style={{ borderRadius: '25px', }}>
              <div className="d-flex">
                <h4 className='my-2 ' style={{ color: "#120c34" }}><b>Attachment (optinal):</b></h4>
                <input type="file" id="fileInput" style={{ margin: '10px 0', display: 'none' }} />
                <label htmlFor="fileInput" className="" style={{ cursor: 'pointer', color: "#120c34" }}>
                  <div className="d-flex align-items-center gap-3">
                    <i className="fa fa-upload my-2 me-2" style={{ fontSize: "1.5em" }}></i>
                    <h4 className='my-2'>Click to Upload</h4>
                  </div>
                </label>
              </div>
            </div>


            <div className="row mt-5 pb-3">
              <div className="col-6 col-sm-6">
                <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Back" />
              </div>
              <div className="col-6 col-sm-6">
                <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Continue
                  </span>
                </button>

              </div>
            </div>

          </div>

        );
      case 3:
        const increment = (id, e) => {
          e.stopPropagation();
          const input = document.getElementById(id);
          let currentValue = parseInt(input.value, 10) || 0;  // Ensure a number is always parsed
          let newValue = currentValue + 1;
          input.value = newValue;  // Update the input's value
          this.handleInputFormChange(id, newValue);  // Call your function with the new value
        };

        const decrement = (id, e) => {
          e.stopPropagation();
          const input = document.getElementById(id);
          let currentValue = parseInt(input.value, 10) || 0;
          let newValue = currentValue - 1;
          input.value = newValue;
          this.handleInputFormChange(id, newValue);
        };


        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='active' id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>

            <div className="container-fluid mt-3">

              <div className="row  mt-3">
                <div className="col-6 col-sm-6 align-items-center justify-content-center text-right" style={{ display: "flex", alignItems: "center" }}>
                  <h2 className='my-3 form_market_font' style={{ fontWeight: "600" }}>in-feed photo post </h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div className="d-flex align-items-center justify-content-center form_market_input" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={(e) => decrement("countInput", e)} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent ' id="countInput" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={(e) => increment("countInput", e)} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-6 col-sm-6 align-items-center justify-content-center text-right" style={{ display: "flex", alignItems: "center" }}>
                  <h2 className='my-3 form_market_font' style={{ fontWeight: "600" }}>15-30 second reel</h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div className="d-flex align-items-center justify-content-center form_market_input" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={(e) => decrement("countInput1", e)} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent ' id="countInput1" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={(e) => increment("countInput1", e)} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-6 col-sm-6 align-items-center justify-content-center text-right" style={{ display: "flex", alignItems: "center" }}>
                  <h2 className='my-3 form_market_font' style={{ fontWeight: "600" }}>3-frame photo story</h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div className="d-flex align-items-center justify-content-center form_market_input  " style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={(e) => decrement("countInput2", e)} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent ' id="countInput2" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={(e) => increment("countInput2", e)} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <div className="row  mt-3">
                <div className="col-6 col-sm-6 align-items-center justify-content-center text-right" style={{ display: "flex", alignItems: "center" }}>
                  <h2 className='my-3 form_market_font' style={{ fontWeight: "600" }}>3-frame video story</h2>
                </div>

                <div className="col-6 col-sm-6">
                  <div className="d-flex align-items-center justify-content-center form_market_input" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <i className="fas fa-minus" onClick={(e) => decrement("countInput3", e)} style={{ fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}></i>
                    <input className='bg-transparent' id="countInput3" type="text" defaultValue="0" readOnly style={{ width: "50%", textAlign: 'center', border: "0", fontWeight: "600" }} />
                    <i className="fas fa-plus" onClick={(e) => increment("countInput3", e)} style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              </div>

              <h3 className='mt-3' style={{ fontWeight: "800" }}><b>Post Frequency</b></h3>

              <div className="row mt-3" >
                <div className="col-5 col-sm-5" >
                  <div className="dropdown show" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <div className="card border-0 text-center p-0">
                      <select className="custom-select" onClick={(e) => e.stopPropagation()} onChange={(e) => this.handleInputFormChange('postFrequencyCount', e.target.value)} style={{ border: 0, background: 'transparent', fontWeight: "600", color: "#120c34", textAlignLast: "center", WebkitAppearance: "none", MozAppearance: "none", appearance: "none" }} aria-label="Post selection">
                        <option selected>1 post</option>
                        <option value="action">Action</option>
                        <option value="another-action">Another action</option>
                        <option value="something-else">Something else here</option>
                      </select>
                      <div className="dropdown-toggle" style={{ position: 'absolute', right: '20px', top: '12px', pointerEvents: 'none' }}></div>
                    </div>
                  </div>

                </div>
                <div className="col-2 col-sm-2" style={{ display: "flex", alignItems: "center" }}>
                  <h2 className='my-3 form_market_font' style={{ fontWeight: "600" }}>per </h2>
                </div>
                <div className="col-5 col-sm-5" >
                  <div className="dropdown show" style={{ borderRadius: '25px', border: '1px solid #ccc', padding: '20px', background: "white", color: "#120c34" }}>
                    <div className="card border-0 text-center p-0">
                      <select className="custom-select" onClick={(e) => e.stopPropagation()} onChange={(e) => this.handleInputFormChange('postFrequencyPer', e.target.value)} style={{ border: 0, background: 'transparent', fontWeight: "600", color: "#120c34", textAlignLast: "center", WebkitAppearance: "none", MozAppearance: "none", appearance: "none" }} aria-label="Post selection">
                        <option selected>Week</option>
                        <option value="action">Action</option>
                        <option value="another-action">Another action</option>
                        <option value="something-else">Something else here</option>
                      </select>
                      <div className="dropdown-toggle" style={{ position: 'absolute', right: '20px', top: '12px', pointerEvents: 'none' }}></div>
                    </div>
                  </div>

                </div>
              </div>


            </div>


            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-6 col-sm-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} value="Back" />
                </div>
                <div className="col-6 col-sm-6">
                  <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Continue
                    </span>
                  </button>

                </div>
              </div>
            </div>


          </div>
        );
      case 4:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='' id="step3"><strong></strong></li>
              <li className='active' id="step4"><strong></strong></li>
              <li id="step5"><strong></strong></li>
            </ul>
            <h3 className='mt-5' style={{ fontWeight: "800" }}><b>Make an offer</b></h3>
            <input type="text" onClick={(e) => e.stopPropagation()} onChange={e => this.handleInputFormChange('offerAmount', e.target.value)} className="btn btn-primary rounded-pill mx-3" style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="$2,000" />

            <h3 className='mt-3' style={{ fontWeight: "800" }}>product included</h3>
            <div className="row mt-3">
              <div className="col-md-6">
                <input type="button" className=" rounded-pill mx-3 selected" onClick={e => this.handleInputFormChange1('productIncluded', e.target.value, e)} style={{ fontWeight: "800", fontSize: "x-large", width: '50%', padding: '15px', borderRadius: "25px", color: this.state.productIncluded === 'yes' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.productIncluded === 'yes' ? 'white' : 'transparent' }} value="yes" />
              </div>
              <div className="col-md-6">
                <input type="button" className=" rounded-pill mx-3" onClick={e => this.handleInputFormChange1('productIncluded', e.target.value, e)} style={{ fontWeight: "800", fontSize: "x-large", width: '50%', padding: '15px', borderRadius: "25px", color: this.state.productIncluded === 'no' ? '#120c34' : 'white', border: "2px solid white", fontSize: "x-large", backgroundColor: this.state.productIncluded === 'no' ? 'white' : 'transparent' }} value="no" />
              </div>
            </div>

            <h3 className='mt-3' style={{ fontWeight: "800" }}>product URL</h3>
            <div className="card mx-5 mt-2 px-2" style={{ borderRadius: '25px', }}>
              <div className="row align-items-center">
                <div className="col-md-12">
                  {/* <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4> */}
                  <input className='border  border-0 form-control' onClick={(e) => e.stopPropagation()} onChange={e => this.handleInputFormChange('productURL', e.target.value)} style={{ fontWeight: "800", fontSize: "x-large", border: '0', borderRadius: '25px' }} type="text" placeholder="www.yourwebiste.com" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>

            <h3 className='mt-3' style={{ fontWeight: "800" }}><b>product value</b></h3>
            <input type="text" className="text-center input-box rounded-pill mx-3" onClick={(e) => e.stopPropagation()} onChange={e => this.handleInputFormChange('productValue', e.target.value)} style={{ fontWeight: "800", fontSize: "x-large", backgroundColor: 'white', width: '50%', padding: '15px', borderRadius: "25px", color: "#120c34" }} placeholder="$0" />



            <div className="container">
              <div className="row mt-5 pb-3">
                <div className="col-6 col-sm-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ fontWeight: "800", fontSize: "x-large", border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="Back" />
                </div>
                <div className="col-6 col-sm-6">
                  <button className='btn btn-lg form-button' onClick={this.nextStep} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block',
                      fontWeight: "800", fontSize: "x-large"
                    }}>
                      Continue
                    </span>
                  </button>

                </div>
              </div>
            </div>
          </div>

        );
      case 5:
        return (
          <div className='text-center' style={{ background: "#120c34", width: "100%", borderRadius: "25px" }}>
            <ul id="progressbar">
              <li className="" id="step1" style={{ listStyleType: 'none' }}><strong></strong></li>
              <li className='' id="step2"><strong></strong></li>
              <li className='' id="step3"><strong></strong></li>
              <li id="step4"><strong></strong></li>
              <li className='active' id="step5"><strong></strong></li>
            </ul>

            <h3 className='mt-3' style={{ fontWeight: "800" }}><b>select deliverable</b></h3>

            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row text-center">

                    <div className="col-md-12 mb-1 text-center">
                      <h5 className='text-center' style={{ fontWeight: "800" }}><b>Request details</b></h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>Start Date : {this.state.startDate}</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.requestType}</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-2">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>Platform : {this.state.Platform}</p>
                      </div>
                    </div>


                    <div className="col-md-12 mb-1 text-center">
                      <h5 className='text-center' style={{ fontWeight: "800" }}><b>Deliverables</b></h5>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.countInput} in-feed photo post</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.countInput1} 15-30 second reel</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.countInput2} frame photo story</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.countInput3} frame video story</p>
                      </div>
                    </div>
                    <div className="col-md-12 mb-1">
                      <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.postFrequencyCount} per week</p>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-md-12 mb-1 text-center">
                    <h5 className='text-center' style={{ fontWeight: "800" }}><b>Instructions</b></h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <textarea
                        className='pt-2'
                        style={{
                          textAlign: 'center',
                          borderRadius: "25px",
                          fontSize: 'large', // Note: Adjusted to use a string value instead of "large"
                          border: 'none', // Adjusted to use 'none' instead of "0"
                          fontWeight: '800',
                          width: "100%",
                          color: '#120c34', // Applied to the textarea itself
                          resize: 'none', // Prevents the user from resizing the textarea
                          overflow: 'hidden', // Ensures the text does not overflow the textarea
                          height: '150px', // Equivalent to 5 rows of text (assuming an average line height)
                        }}
                        rows={5}
                      >
                        {this.state.requestInstructionsText}
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <h5 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h5>
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>{this.state.WebsiteUrl}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center gap-3">
                        <i className="fa fa-upload my-2 me-2" style={{ fontSize: "1.5em", color: "#120c34" }}></i>
                        <h4 className='my-2' style={{ color: "#120c34" }}>photo.jpeg</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-1 text-center">
                    <h5 className='text-center' style={{ fontWeight: "800" }}><b>Offer</b></h5>
                  </div>

                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>$ {this.state.offerAmount}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>Product URL : {this.state.productURL}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-1">
                    <div className="card align-items-center " style={{ borderRadius: "25px" }}>
                      <div className="d-flex align-items-center">
                        <p className='pt-2' style={{ textAlign: 'center', fontSize: "large", border: "0", fontWeight: "800", color: "#120c34" }}>Product Value $ {this.state.productValue}</p>
                      </div>
                    </div>
                  </div>



                </div>
              </div>


            </div>
            <div className="container">
              <div className="row mt-3 pb-3">
                <div className="col-6 col-sm-6">
                  <input type="button" onClick={this.prevStep} className=" rounded-pill mx-3 bg-transparent text-white" style={{ fontWeight: "800", fontSize: "x-large", border: "2px solid white", fontSize: '1.2em', fontWeight: 600, backgroundColor: '', padding: '15px', borderRadius: "25px", color: "#120c34" }} defaultValue="Back" />
                </div>
                <div className="col-6 col-sm-6">
                  <button type="submit" className='btn btn-lg form-button' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block',
                      fontWeight: "800", fontSize: "x-large"
                    }}>
                      Submit
                    </span>
                  </button>

                </div>
              </div>
            </div>

          </div>
        );
      default:
        return null;
    }
  }
  renderModalContent(user) {
    if (this.state.showForm) {
      return (
        <form onSubmit={this.handleSubmit}>
          {this.renderForm()}
        </form>
      );
    } else {
      return (
        <div style={{ borderRadius: "25px" }}>
          {/* <img src={user.images && user.images.length > 0 ? user.images[0] : "/images/person.png"} className='' alt="" style={{ width: "100%", height: "64vh", borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }} /> */}
          <div style={{ position: 'relative' }}>
            {/* <img
              src={user.images && user.images.length > 0 ? user.images[0] : "/images/person.png"}
              alt=""
              style={{ width: "100%", height: "64vh", borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}
            /> */}
            <div className="text-center" style={{ position: "relative", overflow: "hidden" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ zIndex: "10000000000", position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', fontSize: '1.5rem', color: '#fff' }}
              >
                {/* <span aria-hidden="true">&times;</span> */}
                <i class="fa-solid fa-xmark fa-2x" style={{ color: "white" }}></i>
              </button>
              <div
                className="background-image"
                style={{
                  backgroundImage: `url(${user.images ? user.images[0] : "/images/person.png"})`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  filter: "blur(40px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1
                }}
              ></div>
              <img
                className="original-image"
                src={user.images ? user.images[0] : "/images/person.png"}
                alt="User"
                style={{
                  height: "65vh",
                  position: "relative",
                  zIndex: 2
                }}
              />
            </div>
            <div class="blur-effect"></div>
            <div class="shade-effect "></div>


          </div>
          <div className="shade-effect1"></div>

          <div className='profile' style={{ borderRadius: "25px", background: "#120c34" }}>

            <div className="card-body1  px-3 pt-4" style={{ background: "#120c34" }}>
              <h3 className="card-text alignleft name_font"><b> {user.name.toUpperCase()}</b></h3>
              <h3 className="card-text alignright location_font" style={{ fontWeight: "bold", fontSize: "1.1rem" }}>{user.location ? this.truncateAddress(user.location, 5) : "No location"}&nbsp;<i className="fa-solid fa-location-dot"></i></h3>
            </div>


            <div className="card-body2 px-3  d-grid py-2">
              <a href="" style={{ textDecoration: 'none' }}>
                <img src="/images/instagram.png" className='social_logo' alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <h3 className='social_font' style={{ fontWeight: "bold" }}>{user.social && user.social.instagram ? user.social.instagram.toLocaleString() : '0'}</h3>
              <br />
              <a href="" style={{ textDecoration: 'none' }}>
                <img src="/images/tiktok.png" className='social_logo' alt="" style={{ width: "30px", height: "30px" }} />
              </a>
              <h3 className='social_font' style={{ fontWeight: "bold" }}>{user.social && user.social.tiktok ? user.social.tiktok.toLocaleString() : '0'}</h3>
            </div>

            <div className="d-grid px-3 mb-2">
              <button className='small-rounded-button-modal mr-3 px-2' style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>cool</button>
              <button className='small-rounded-button-modal mr-3 px-2' style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>cool</button>
              <button className="small-rounded-button-modal mr-3 px-2" style={{ borderRadius: "25px", fontWeight: "800", cursor: "default", color: "#120c34" }} disabled>Click Me</button>
            </div>

            <div className="text-center pb-3">
              <button className='btn btn-lg form-button' onClick={this.toggleForm} style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                <span style={{
                  color: '#fff',
                  lineHeight: 'inherit',
                  cursor: 'pointer',
                  padding: '9px 15px',
                  textDecoration: 'none',
                  display: 'inline-block'
                }}>
                  Start Request
                </span>
              </button>
            </div>

          </div>

        </div>

      );
    }
  }


  render() {
    // const { isLoading } = this.state;
    const { loading, error } = this.state;
    const { selectedCountry, onCountryChange } = this.props;
    const { isLoading } = this.state;

    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }
    const { gender } = this.state;
    const selectedStyle = {
      color: 'white',
      backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)',
      border: '#120c34 2px solid'
    };
    const unselectedStyle = {
      color: '#120c34',
      backgroundColor: 'white',
      border: '#120c34 2px solid'
    };

    return (
      <div className='bg-white'>
        <div className="container-fluid " style={{ height: "70vh" }}>
          <div className="row mb-3">
            <div className="col-md-3  " style={{ heigt: "80vh" }}>
              <p className='font-type mb-1' style={{ maxWidth: 'none', color: '#120c34', textAlign: 'center', fontSize: '3em' }}>Marketplace</p>



              <div className="container-fluid mb-3 hidden-on-mobile " style={{ background: "#120c34", borderRadius: "20px" }}>
                <h1 className='text-white text-center p-3' style={{ fontWeight: "800", }}>Filters</h1>

                <div className="box p-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Age</h4>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                    <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Min" value={this.state.min} onChange={(e) => this.handleInputChange('min_age', e.target.value)} />
                    <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Max" value={this.state.max} onChange={(e) => this.handleInputChange('max_age', e.target.value)} />
                  </div>
                </div>
                <div className="box1 p-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Gender</h4>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                    {/* <input onClick={(e) => this.handleInputChange('gender', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3 border-light" style={{ color: this.state.gender === 'Male' ? 'white' : '#120c34', fontSize: "1em", backgroundColor: this.state.gender === 'Male' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", fontWeight: "800", border: "#120c34 2px solid" }} value="Male" />
                    <input onClick={(e) => this.handleInputChange('gender', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3 border-light" style={{ color: this.state.gender === 'Female' ? 'white' : '#120c34', fontSize: "1em", backgroundColor: this.state.gender === 'Female' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", fontWeight: "800", border: "#120c34 2px solid" }} value="Female1" /> */}
                    <button
                      onClick={(e) => this.handleInputChange('gender', this.state.gender === 'Male' ? '' : e.target.value)}
                      className="btn btn-lg rounded-pill mx-3"
                      style={{
                        ...unselectedStyle,
                        ...(gender === 'Male' && selectedStyle),
                        fontSize: "1em",
                        width: '30%',
                        borderRadius: "25px",
                        fontWeight: "800"
                      }}
                      value="Male"
                    >
                      Male
                    </button>
                    <button
                      onClick={(e) => this.handleInputChange('gender', this.state.gender === 'Female' ? '' : e.target.value)}
                      className="btn btn-lg rounded-pill mx-3"
                      style={{
                        ...unselectedStyle,
                        ...(gender === 'Female' && selectedStyle),
                        fontSize: "1em",
                        width: '30%',
                        borderRadius: "25px",
                        fontWeight: "800"
                      }}
                      value="Female"
                    >
                      Female
                    </button>
                  </div>
                </div>

                {/* <div className="box1 p-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Location</h4>
                  <select className="custom-select" style={{ borderRadius: "25px" }} value={selectedCountry} onChange={onCountryChange}>
                    {this.renderCountriesOptions()}
                  </select>
                </div> */}


                <div className="box2 p-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Follower Range</h4>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                    {/* <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }} className='btn btn-lg'>min</button>
                    <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }}>max</button> */}
                    <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Min" value={this.state.min_range} onChange={(e) => this.handleInputChange('followers_min', e.target.value)} />
                    <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Max" value={this.state.max_range} onChange={(e) => this.handleInputChange('followers_max', e.target.value)} />
                  </div>
                </div>


                <div className="box3 pb-3">
                  <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Interests</h4>
                  <div>
                    <div className="row text-center mx-1 mb-3">
                      <div className="">
                        <button className='filter-btn' onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Acting</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Art</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Baking</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Beer</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Books</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Business</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cars</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Coffee</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cooking</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cycling</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Dancing</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Entrepreneurship</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Fashion</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Fitness</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Film</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Health</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Modeling</button>
                        <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Music</button>
                      </div>
                    </div>
                  </div>
                </div>



              </div>


            </div>
            <div className="col-md-9 col-sm-12">
              <div className='pt-3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className='btn btn-lg mr-5' style={{ display: this.state.selected_creators >= 1 ? 'block' : 'none', letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Send Request
                  </span>
                </button>
                <Link to='/marketplace/favorites' className='pl-2 btn btn-lg mr-5' style={{ letterSpacing: '-.02em', textTransform: 'none', borderRadius: '25px', borderColor: "#120c34 ", marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#120c34',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Favorites
                  </span>
                </Link>
                <Link to='/manage_req' className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Manage Requests
                  </span>
                </Link>
              </div>
              <div className='container-fluid mt-3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className='hidden-on-web mr-0 ' style={{ letterSpacing: '-.02em', textTransform: 'none', borderRadius: '25px', borderColor: "#120c34 ", marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s' }}>
                  <span style={{
                    color: '#120c34',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    // backgroundColor: '#3898ec',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    <i class="fa-solid fa-filter"></i> Filter
                  </span>
                </a>
              </div>
              <div class="collapse" id="collapseExample">
                <div className="container-fluid mb-3 " style={{ background: "#120c34", borderRadius: "20px" }}>
                  <h1 className='text-white text-center p-3' style={{ fontWeight: "800", }}>Filters</h1>

                  <div className="box p-3">
                    <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Age</h4>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                      <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Min" value={this.state.min} onChange={(e) => this.handleInputChange('min_age', e.target.value)} />
                      <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Max" value={this.state.max} onChange={(e) => this.handleInputChange('max_age', e.target.value)} />
                    </div>
                  </div>
                  <div className="box1 p-3">
                    <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Gender</h4>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                      <input onClick={(e) => this.handleInputChange('gender', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3 border-light" style={{ color: this.state.gender === 'Male' ? 'white' : '#120c34', fontSize: "1em", backgroundColor: this.state.gender === 'Male' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", fontWeight: "800", border: "#120c34 2px solid" }} value="Male" />
                      <input onClick={(e) => this.handleInputChange('gender', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3 border-light" style={{ color: this.state.gender === 'Female' ? 'white' : '#120c34', fontSize: "1em", backgroundColor: this.state.gender === 'Female' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", fontWeight: "800", border: "#120c34 2px solid" }} value="Female" />
                    </div>
                  </div>

                  <div className="box1 p-3">
                    <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Location</h4>
                    {/* <CountryDropdown selectedCountry={formData.countryId} onCountryChange={handleCountryChange} /> */}
                    {/* {this.CountryDropdown()} */}
                    <select className="custom-select" style={{ borderRadius: "25px" }} value={selectedCountry} onChange={onCountryChange}>
                      {this.renderCountriesOptions()}
                    </select>
                  </div>


                  <div className="box2 p-3">
                    <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Follower Range</h4>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '30%' }}>
                      {/* <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }} className='btn btn-lg'>min</button>
                    <button style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px' }}>max</button> */}
                      <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Min" value={this.state.min_range} onChange={(e) => this.handleInputChange('followers_min', e.target.value)} />
                      <input className='text-black text-center' type="text" style={{ fontWeight: "800", borderRadius: '25px', backgroundColor: 'white', color: '#120c34', padding: '10px 20px', fontSize: '1em', border: 'none', cursor: 'pointer', margin: '0 5px', width: "30%", cursor: "text" }} placeholder="Max" value={this.state.max_range} onChange={(e) => this.handleInputChange('followers_max', e.target.value)} />
                    </div>
                  </div>


                  <div className="box3 pb-3">
                    <h4 className='text-white text-center' style={{ fontWeight: "800", }}>Interests</h4>
                    <div>
                      <div className="row text-center mx-1 mb-3">
                        <div className="">
                          <button className='filter-btn' onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Acting</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Art</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Baking</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Beer</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Books</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Business</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cars</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Coffee</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cooking</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Cycling</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Dancing</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Entrepreneurship</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Fashion</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Fitness</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Film</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Health</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Modeling</button>
                          <button onClick={e => this.handleToggleBTN()} style={{ backgroundImage: this.state.isActive === false ? 'linear-gradient(to right, white, rgba(255, 255, 255, 0))' : 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', fontWeight: "800", backgroundColor: 'white', color: this.state.isActive === false ? '#120c34' : 'white', borderRadius: '25px', padding: '10px 20px', fontSize: '0.8em', border: 'none', cursor: 'pointer', margin: '5px 5px 0 0' }}>Music</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="container-fluid mt-2 ">

                <div className="row">
                  {isLoading ? (
                    <div className='container d-flex justify-content-center align-items-center'>
                      <Animation_Custom />
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        {this.renderCards()}
                      </div>
                      <div className="container d-flex justify-content-center align-items-center ">
                        <small onClick={this.loadMore} className='m-3 text-center' style={{ fontSize: "20px", color: "#120c34" }}><u> Load More Creators</u></small>
                      </div>
                    </>
                  )}

                </div>

              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}
