import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';

export default class Profile_DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            error: null,
            isEditing: false,
            editedData: {},
            states: []
        };
    }

    isLogedin = () => {
        return localStorage.getItem("isLogedin") === 'true';
    }

    fetchStates = async () => {
        try {
            const response = await fetch('https://api.embassy.io/api/general/v1/state');
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'SUCCESS') {
                    this.setState({ states: result.data });
                } else {
                    throw new Error('Failed to fetch states');
                }
            } else {
                throw new Error('Failed to fetch states');
            }
        } catch (error) {
            console.error('Error fetching states:', error);
            this.setState({ error: 'Failed to load states. Please try again.' });
        }
    }

    handleStateChange = (event) => {
        const selectedStateId = event.target.value;
        const selectedState = this.state.states.find(state => state.id === parseInt(selectedStateId));
        this.setState(prevState => ({
            editedData: {
                ...prevState.editedData,
                state: selectedState ? selectedState : prevState.editedData.state
            }
        }));
    }

    renderStateSelect = () => {
        const { states, editedData, data } = this.state;
        console.log("States in renderStateSelect:", states);
        if (!states || states.length === 0) {
            console.log("No states available");
            return null;
        }
        console.log("Data in renderStateSelect:", data);
        const currentStateId = editedData.state ? editedData.state.id : '';

        return (
            <select
                style={{ fontSize: "x-large", outline: 'none', borderRadius: "25px", width: "100%", color: "#120c34" }}
                className="form-select rounded-pill p-1 border-0 text-center"
                value={currentStateId}
                onChange={this.handleStateChange}
            >
                <option value="">Select a state</option>
                {states.map(state => (
                    <option key={state.id} value={state.id}>
                        {`${state.name}, ${state.subcountry}, ${state.country}`}
                    </option>
                ))}
            </select>
        );
    }

    backButtonStyle = {
        background: 'none',
        border: 'none',
        color: 'inherit',
        padding: 0,
        fontSize: 'inherit',
        cursor: 'pointer',
        marginBottom: '5px',
        fontSize: "25px"
    };

    componentDidMount() {
        if (!this.isLogedin()) {
            window.location.href = '/signin';
            return;
        }

        this.fetchData();
        this.fetchStates();
    }

    fetchData = async () => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            window.location.href = '/signin';
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'SUCCESS') {
                    console.log(this.state.data)
                    this.setState({ data: result.data, editedData: result.data });
                } else if (result.code === 'token_not_valid') {
                    this.handleLogout();
                } else {
                    throw new Error('Failed to fetch data');
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            this.setState({ error: error.message });
            console.error(error.message);
        }
    };

    handleLogout = () => {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("isLogedin");
        console.error('Token is invalid or expired');
        window.location.href = '/signin';
    }

    toggleEdit = () => {
        this.setState(prevState => ({ isEditing: !prevState.isEditing }));
    }

    handleInputChange = (field, value) => {
        this.setState(prevState => ({
            editedData: {
                ...prevState.editedData,
                [field]: value
            }
        }));
    }

    handleSubmit = async () => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            window.location.href = '/signin';
            return;
        }

        try {
            const editedDataWithGenderCode = {
                ...this.state.editedData,
                gender: this.state.editedData.gender === "Male" ? 1 : (this.state.editedData.gender === "Female" ? 2 : this.state.editedData.gender)
            };
            console.log(editedDataWithGenderCode);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/auth/v1/user/${this.state.data.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                
                body: JSON.stringify(editedDataWithGenderCode)
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status === 'SUCCESS') {
                    this.setState({ data: this.state.editedData, isEditing: false });
                    // alert('Profile updated successfully');
                    window.location.reload();
                } else {
                    throw new Error('Failed to update profile');
                }
            } else {
                throw new Error('Failed to update profile');
            }
        } catch (error) {
            this.setState({ error: error.message });
            console.error(error.message);
        }
    }

    render() {
        const { data, error, isEditing, editedData, states } = this.state;

        return (
            <div className='container-fluid' >
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col-md-1">
                        <button onClick={() => window.history.back()} style={this.backButtonStyle}>
                            ← Back
                        </button>
                    </div>
                    <div className="offset-2 col-md-6 text-center">
                    </div>
                    <div className="col-md-3 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={isEditing ? this.handleSubmit : this.toggleEdit} className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 1px solid", borderRadius: "25px", fontSize: "x-large" }}>
                            <i className={`fa-solid ${isEditing ? 'fa-save' : 'fa-pen'}`}></i> 
                            {isEditing ? 'Save Profile' : 'Edit Profile'}
                        </button>
                    </div>
                </div>
                <div className='container-fluid' style={{ width: "90%" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <h3 className='text-center' style={{ color: "#120c34" }}>Account settings </h3>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img className='img-fluid ' src={data.images} alt="" style={{ width: "200px", height: "200px", borderRadius: "50%", border: "#120c34 solid 4px", objectFit: "contain", }} />
                    </div>
                    <h2 className='text-center mt-3' style={{ color: "#120c34", textTransform: "uppercase" }}>{data.name}</h2>

                    <div className="row">
                        <div className="col-12">
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <h3 className='text-center mt-3' style={{ color: "#120c34", textTransform: "uppercase" }}>{data.email}</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5 ">
                        <div className="col-4" style={{ width: "80%" }}>
                            <div >
                                <h3 className=' mt-3' style={{ color: "#120c34", textTransform: "uppercase" }}>Settings</h3>
                                <div className="text-center" style={{ borderRadius: "25px", width: "100%", maxWidth: "400px" }}>
                                    <Link className='btn btn-lg' style={{ width: "100%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: 'large', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{
                                            color: '#fff',
                                            lineHeight: 'inherit',
                                            cursor: 'pointer',
                                            border: '0',
                                            borderRadius: '0',
                                            padding: '9px 15px',
                                            textDecoration: 'none',
                                            display: 'inline-block'
                                        }}>
                                            Change Password
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div >
                                <h3 className=' mt-3' style={{ color: "#120c34", textTransform: "uppercase" }}>Date of Birth</h3>
                                <div className="" style={{ border: "#120c34 2px solid", borderRadius: "25px", width: "100%", maxWidth: "400px" }}>
                                    {isEditing ? (
                                        <DatePicker
                                            selected={editedData.dob ? new Date(editedData.dob) : null}
                                            onChange={(date) => this.handleInputChange('dob', date)}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="mm/dd/yyyy"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            customInput={
                                                <input
                                                    type="text"
                                                    style={{
                                                        fontSize: "x-large",
                                                        outline: 'none',
                                                        borderRadius: "25px",
                                                        width: "100%",
                                                        color: "#120c34"
                                                    }}
                                                    className="input-box rounded-pill p-1 border-0 text-center"
                                                />
                                            }
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            style={{
                                                fontSize: "x-large",
                                                outline: 'none',
                                                borderRadius: "25px",
                                                width: "100%",
                                                color: "#120c34"
                                            }}
                                            className="input-box rounded-pill p-1 border-0 text-center"
                                            value={editedData.dob ? format(new Date(editedData.dob), 'MM/dd/yyyy') : "Not set"}
                                            readOnly
                                        />
                                    )}
                                </div>
                            </div>
                            <div >
                                <h3 className=' mt-5 ' style={{ color: "#120c34", textTransform: "uppercase" }}>Gender</h3>
                                <div className="" style={{ border: "#120c34 2px solid", borderRadius: "25px", width: "100%", maxWidth: "400px" }}>
                                    <input
                                        type="text"
                                        style={{ fontSize: "x-large", outline: 'none', borderRadius: "25px", width: "100%", color: "#120c34" }}
                                        className="input-box rounded-pill p-1 border-0 text-center"
                                        value={editedData.gender}
                                        onChange={(e) => this.handleInputChange('gender', e.target.value)}
                                        readOnly={!isEditing}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div >
                                <h3 className=' mt-3' style={{ color: "#120c34", textTransform: "uppercase" }}>Mobile</h3>
                                <div className="" style={{ border: "#120c34 2px solid", borderRadius: "25px", width: "100%", maxWidth: "400px" }}>
                                    <input
                                        type="text"
                                        style={{ fontSize: "x-large", outline: 'none', borderRadius: "25px", width: "100%", color: "#120c34" }}
                                        className="input-box rounded-pill p-1 border-0 text-center"
                                        value={editedData.mobile_number}
                                        onChange={(e) => this.handleInputChange('mobile_number', e.target.value)}
                                        readOnly={!isEditing}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className=' mt-5' style={{ color: "#120c34", textTransform: "uppercase" }}>Location</h3>
                                <div className="" style={{ border: "#120c34 2px solid", borderRadius: "25px", width: "100%", maxWidth: "400px" }}>
                                    {isEditing ? this.renderStateSelect() : (
                                        <input
                                            type="text"
                                            style={{ fontSize: "x-large", outline: 'none', borderRadius: "25px", width: "100%", color: "#120c34" }}
                                            className="input-box rounded-pill p-1 border-0 text-center"
                                            value={editedData.state ? `${editedData.state.name}, ${editedData.state.subcountry}, ${editedData.state.country}` : "Not set"}
                                            readOnly
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
