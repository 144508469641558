import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Apiloading_animation from '../Animation/Apiloading_animation';
import { FaCopy } from 'react-icons/fa';

export default function Manage_campaign() {
    const [campaigns, setCampaigns] = useState([]);
    const [showSubmitButton, setShowSubmitButton] = useState(false);



    const backButtonStyle = {
        background: 'none',
        border: 'none',
        color: 'inherit',
        padding: 0,
        fontSize: 'inherit',
        cursor: 'pointer',
        marginBottom: '5px',
        fontSize: "25px",
    };

    const [data, setData] = useState([]);
    const [maskedUrl, setMaskedUrl] = useState([]);
    const [responses, setResponses] = useState({});

    const copyToClipboard = () => {
        if (maskedUrl) {
            navigator.clipboard.writeText(maskedUrl).then(() => {
                toast.success('URL copied to clipboard!')
            }).catch(err => {
                // console.error('Failed to copy: ', err);
                toast.error('Failed to copy: ', err)
            });
        }
    };

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                const response = await fetch(`https://api.embassy.io/api/campaign/v1/content/${campaignId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                const apiData = result.data.results.map(item => ({
                    id: item.id,
                    image: item.image,
                    status: item.status,
                }));
                const maskedurl = result.data.masked_url
                setData(apiData);
                setMaskedUrl(maskedurl)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    function DisplayAttachments({ campaigns }) {
        // Check if campaigns, campaigns.details, and campaigns.details.attachments are valid
        const attachments = campaigns && campaigns.details && Array.isArray(campaigns.details.attachments) ? campaigns.details.attachments : [];
        // console.log("attachments: ", attachments);

        if (attachments.length === 1) {
            return <a className="" href={attachments[0]} target="_blank" rel="noopener noreferrer">Campaign Guidelines</a>;
        } else if (attachments.length > 1) {
            return (
                <div className="dropdown">
                    <button className="bg-transparent border-0 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {attachments.length} Campaign Guidelines
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {attachments.map((attachment, index) => (
                            <a key={index} className="dropdown-item" href={attachment} target="_blank" rel="noopener noreferrer">Attachment {index + 1}</a>
                        ))}
                    </div>
                </div>
            );
        } else {
            return <p>No attachments available</p>;
        }
    }

    const location = useLocation();
    const campaignId = new URLSearchParams(location.search).get('id');
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${campaignId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status === 'SUCCESS') {
                setCampaigns(result.data);
                setShowSubmitButton(result.data.joined);
                // console.log("coolmanaksnkndans")
                // console.log(result.data)

            } else {
                console.error('Failed to fetch campaigns');
            }
        };

        fetchData();
    }, []);


    // const startDate = campaigns && campaigns.details ? campaigns.details.startDate : '';
    // const endDate = campaigns && campaigns.details ? campaigns.details.endDate : '';
    const images = campaigns && campaigns.brand ? campaigns.brand.images : '';
    const name = campaigns && campaigns.brand ? campaigns.brand.name : '';
    const desc = campaigns && campaigns.details ? campaigns.details.description : '';
    const url = campaigns && campaigns.details ? campaigns.details.url : '';
    const attachment = campaigns && campaigns.details ? campaigns.details.attachments : '';
    const campaign_id = campaigns.id;
    // console.log("campaigns.joined: ", campaigns.joined)


    const SubmitButton = ({ showSubmitButton }) => {
        return (

            <div className="col-12 text-center mb-4" style={{ display: showSubmitButton ? 'block' : 'none' }}>
                <button data-toggle="modal" data-target="#exampleModal" className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                        color: '#fff',
                        lineHeight: 'inherit',
                        cursor: 'pointer',
                        border: '0',
                        borderRadius: '0',
                        padding: '9px 15px',
                        textDecoration: 'none',
                        display: 'inline-block'
                    }}>
                        Submit content for approval
                    </span>
                </button>
                {/* <br />
                <div className="row">
                    <div className="col-12">
                        <small style={{ fontWeight: "800", color: "#120c34" }}>Uploaded content status! </small>
                    </div>
                </div> */}

            </div>




        );
    };


    const JoinCampaignButton = ({ campaignId, fetchData, setShowSubmitButton }) => {
        const handleJoinCampaign = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                console.error("No access token found");
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/join/${campaignId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    // console.log("Successfully joined campaign:", data);
                    setShowSubmitButton(true); // Show the button
                    // console.log("setShowSubmitButton: ", showSubmitButton)
                    fetchData(); // Fetch data again after joining the campaign
                } else {
                    console.error("Failed to join campaign:", response.statusText);
                }
            } catch (error) {
                console.error("Error joining campaign:", error);
            }
        };

        return (
            <input
                onClick={handleJoinCampaign}
                type="button"
                className="p-1 btn btn-lg rounded-pill set_wid50 set_wid15"
                style={{ color: '#120c34', fontSize: "larger", backgroundColor: 'white', borderRadius: "25px", border: "#120c34 2px solid" }}
                value="Join Campaign"
            />
        );
    };


    const LeaveCampaignButton = ({ campaignId, fetchData, setShowSubmitButton }) => {
        const handleLeaveCampaign = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                console.error("No access token found");
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/leave/${campaignId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    // console.log("Successfully left campaign:", data);
                    setShowSubmitButton(false); // Hide the button
                    // console.log("setShowSubmitButton: ", showSubmitButton)
                    fetchData(); // Fetch data again after leaving the campaign
                } else {
                    console.error("Failed to leave campaign:", response.statusText);
                }
            } catch (error) {
                console.error("Error leaving campaign:", error);
            }
        };

        return (
            <>
                <div className='text-center' style={{ display: "in-line" }}>

                    <input
                        data-toggle="modal" data-target="#exampleModalCenter"
                        type="button"
                        className="p-1 btn btn-lg rounded-pill set_wid50 set_wid15 mr-2"
                        style={{ color: "white", backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: "larger", fontWeight: 600, transition: 'transform .3s', display: 'inline-block' }}
                        value="Check Approval"
                    />

                    <input
                        onClick={handleLeaveCampaign}
                        type="button"
                        className="p-1 btn btn-lg rounded-pill set_wid50 set_wid15 ml-2"
                        style={{ color: '#120c34', fontSize: "larger", backgroundColor: 'white', borderRadius: "25px", border: "#120c34 2px solid" }}
                        value="Leave Campaign"
                    />
                </div>

            </>

        );
    };



    const CampaignComponent = ({ campaignId, setShowSubmitButton }) => {
        const [campaigns, setCampaigns] = useState(null);

        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                console.error("No access token found");
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${campaignId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();
                if (result.status === 'SUCCESS') {
                    setCampaigns(result.data);
                    setShowSubmitButton(result.data.joined); // Show button if campaign is joined
                    // console.log("Campaign data fetched successfully:", result.data);
                } else {
                    console.error('Failed to fetch campaigns');
                }
            } catch (error) {
                console.error("Error fetching campaign data:", error);
            }
        };

        useEffect(() => {
            fetchData();
        }, []);

        return (
            <div>
                {campaigns && campaigns.joined ? (
                    <LeaveCampaignButton campaignId={campaignId} fetchData={fetchData} setShowSubmitButton={setShowSubmitButton} />
                ) : (
                    <JoinCampaignButton campaignId={campaignId} fetchData={fetchData} setShowSubmitButton={setShowSubmitButton} />
                )}
            </div>
        );
    };



    const TableComponent = () => {


        const handleInputChange = (id, value) => {
            setResponses(prevResponses => ({
                ...prevResponses,
                [id]: value,
            }));
        };

        const handleSaveClick = (id) => {
            // console.log(`Saved response for ${id}:`, responses[id]);
            // Implement the save functionality as needed
        };

        return (
            <div className="">
                <table className="table mb-0" style={{ background: "#120c34", color: 'white' }}>
                    <colgroup>
                        <col className="image-col" />
                        <col className="status-col" />
                        <col className="input-col" />
                    </colgroup>
                    <thead className="text-center">
                        <tr>
                            <th scope="col">Content</th>
                            <th scope="col">Status</th>
                            <th scope="col">Upload URL</th>
                        </tr>
                    </thead>
                    <tbody className='text-center' >
                        {data.map(item => (
                            <tr key={item.id}>
                                <td data-label="Image">
                                    <a href={item.image} target="_blank" rel="noopener noreferrer" >
                                        <img width="74px" style={{ height: "50px" }} src={item.image} alt="House" className="img-fluid" />
                                    </a>
                                </td>
                                <td data-label="Status" >
                                    {item.status === 'Rejected' ? (
                                        <span className="badge badge-danger">{item.status}</span>
                                    ) : item.status === 'Approved' ? (
                                        <span className="badge badge-success">{item.status}</span>
                                    ) : (
                                        <span className="badge badge-dark">{item.status}</span>
                                    )}
                                </td>
                                <td data-label="Enter Input">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Upload your url"
                                            value={responses[item.id] || ''}
                                            onChange={(e) => handleInputChange(item.id, e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-success"
                                                onClick={() => handleSaveClick(item.id)}
                                            >
                                                ✔
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };





    const UploadForm = () => {
        const [desc, setDesc] = useState('');
        const [fileCount, setFileCount] = useState(0);
        const [selectedFiles, setSelectedFiles] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const fileInputRef = useRef(null);
        const modalRef = useRef(null);

        const handleFileChange = (event) => {
            const files = Array.from(event.target.files);
            setSelectedFiles(files);
            setFileCount(files.length);
            // console.log('Files selected:', files);
        };

        const handleLabelClick = () => {
            if (fileInputRef.current) {
                fileInputRef.current.click();
            }
        };
        const handleRemoveFile = (index) => {
            setSelectedFiles(prevFiles => {
                const updatedFiles = [...prevFiles];
                updatedFiles.splice(index, 1);
                return updatedFiles;
            });
        };
        const truncateFileName = (fileName) => {
            // Split the file name by spaces into an array of words
            const words = fileName.split(' ');

            // If there are more than 5 words, return the first 5 joined with a space and add ellipsis
            if (words.length > 5) {
                return words.slice(0, 4).join(' ') + '...';
            }

            // If there are 5 or fewer words, return the full name
            return fileName;
        };

        const uploadFileToAzure = async (file, sasUrl) => {
            try {
                const response = await fetch(sasUrl, {
                    method: 'PUT',
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': file.type || 'application/octet-stream',
                    },
                    body: file,
                });

                if (!response.ok) {
                    throw new Error('Failed to upload file.');
                }
                // console.log('File uploaded successfully to:', sasUrl);
            } catch (error) {
                console.error('Error uploading file:', error);
                throw error;  // Rethrow to handle in the calling function
            }
        };

        const handleSubmit = async (event) => {
            event.preventDefault();
            setIsLoading(true);

            if (selectedFiles.length > 0) {
                // Create an object to count files by extension
                const fileCountByExtension = selectedFiles.reduce((acc, file) => {
                    const ext = file.name.split('.').pop();
                    acc[ext] = (acc[ext] || 0) + 1;
                    return acc;
                }, {});

                // Convert the object into the required format for the API
                const uploadUrlBody = {
                    count: Object.entries(fileCountByExtension).map(([extension, count]) => ({
                        extension,
                        count
                    }))
                };

                const uploadUrlOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(uploadUrlBody)
                };

                try {
                    const uploadUrlResponse = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`, uploadUrlOptions);
                    const uploadUrlData = await uploadUrlResponse.json();

                    if (!uploadUrlResponse.ok) {
                        throw new Error(`Failed to obtain upload URLs: ${uploadUrlData.error || 'Unknown Error'}`);
                    }

                    // console.log('Upload URLs:', uploadUrlData);
                    if (uploadUrlData.status === "SUCCESS") {
                        // Upload each file to the provided URL
                        const attachments = uploadUrlData.data.map(item => item.key);
                        for (let i = 0; i < selectedFiles.length; i++) {
                            await uploadFileToAzure(selectedFiles[i], uploadUrlData.data[i].url);
                        }

                        // Create the final request body for the campaign API
                        const campaignId = new URLSearchParams(window.location.search).get('id');
                        const campaignBody = {
                            campaign_id: campaignId,
                            caption: desc,
                            uploads: attachments
                        };

                        const campaignOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(campaignBody)
                        };

                        const campaignResponse = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/content`, campaignOptions);
                        const campaignData = await campaignResponse.json();

                        if (!campaignResponse.ok) {
                            throw new Error(`Failed to submit campaign content: ${campaignData.error || 'Unknown Error'}`);
                        }

                        // console.log('Campaign submitted successfully:', campaignData);
                        toast.success('Campaign submitted successfully!');
                        setSelectedFiles([]);
                        setDesc('');

                        //close modal automatically
                        const modal = document.getElementById('exampleModal');
                        if (modal) {
                            modal.classList.remove('show');
                            modal.setAttribute('aria-hidden', 'true');
                            modal.setAttribute('style', 'display: none');
                            const modalBackdrop = document.querySelector('.modal-backdrop');
                            if (modalBackdrop) {
                                modalBackdrop.remove();
                            }
                            document.body.classList.remove('modal-open');
                        }


                    }
                } catch (error) {
                    console.error('Error fetching upload URLs or uploading files:', error);
                    setIsLoading(false); // Stop the loading state on error
                    return; // Stop the submission if file upload URLs cannot be obtained
                }
            }
            setIsLoading(false); // Stop the loading state on success
        };

        return (
            <form onSubmit={handleSubmit}>
                <div className="modal-body border border-light" style={{ background: "#120c34", borderRadius: "25px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="card" style={{ width: "50%", borderRadius: "25px" }}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                multiple
                                onChange={handleFileChange}
                            />
                            <div
                                onClick={handleLabelClick}
                                style={{ cursor: 'pointer', color: "#120c34" }}
                            >
                                <div className="text-center">
                                    <i className="fa-solid fa-upload fa-4x mt-3"></i>
                                    <p style={{ fontWeight: "800", fontSize: "large" }}>Tap to upload</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='text-light text-center mt-2' style={{ fontWeight: "800" }}>{selectedFiles.length}/5 maximum deliverables submitted</p>

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {selectedFiles.map((file, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '100%', border: '1px solid #ccc', borderRadius: '20px', padding: '10px', background: '#f9f9f9' }}>
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: '5px', marginRight: '10px' }}
                                />
                                <div style={{ flex: 1 }}>
                                    <p style={{ margin: 0 }}>{truncateFileName(file.name)}</p>
                                </div>
                                <button type="button" onClick={() => handleRemoveFile(index)} style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="text-center">
                        <textarea
                            className="px-3 mr-3"
                            style={{
                                width: "100%",
                                height: '20em',
                                borderRadius: '25px',
                                resize: 'none',
                                cursor: "default"
                            }}
                            placeholder="Tell the creators what you'd like to post"
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                        />
                        {/* <div className="" style={{display:"flex",justifyContent:"center",fontWeight:"bold"}}>
                            <a className='text-white' href={maskedUrl}>{maskedUrl}</a>
                        </div> */}
                        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold", alignItems: "center" }}>
                            <a className='text-white' href={maskedUrl} style={{ marginRight: '10px' }}>{maskedUrl}</a>
                            <FaCopy style={{ cursor: 'pointer', color: "white" }} onClick={copyToClipboard} />
                        </div>
                        <div className="row text-center">
                            {/* <p>{maskedurl}</p> */}
                            <div className='col-12 text-center mb-3 mt-3'>
                                <button type="submit" className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                    <span style={{
                                        color: '#fff',
                                        lineHeight: 'inherit',
                                        cursor: 'pointer',
                                        border: '0',
                                        borderRadius: '0',
                                        padding: '9px 15px',
                                        textDecoration: 'none',
                                        display: 'inline-block'
                                    }}>
                                        {isLoading ? (
                                            <>
                                                {/* <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                                                Loading... */}
                                                <Apiloading_animation />
                                                Uplading...

                                            </>
                                        ) : (
                                            'Submit content'
                                        )}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    };
    const ManageCampaign = ({ campaignId }) => {
        const [showSubmitButton, setShowSubmitButton] = useState(false);

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-0">
                            <button onClick={() => window.history.back()} style={backButtonStyle}>
                                ← Back
                            </button>
                        </div>
                        <div className="col-12 col-sm-12 text-center mt-0">
                            <img src={images} alt="" style={{ width: "150px", height: "150px", borderRadius: "50%", border: "#120c34 solid 4px" }} />
                            <p className='my-1' style={{ fontWeight: "800", fontSize: "larger" }}>{name}</p>
                            <h3 className='mt-1' style={{ fontWeight: "800", }}>{campaigns.title}</h3>
                            <h3 className='mt-1' style={{ fontWeight: "800", }}>{new Date(campaigns.start_datetime).toLocaleDateString('en-US')} - {campaigns.end_datetime || "Running"}</h3>
                            <CampaignComponent campaignId={campaignId} setShowSubmitButton={setShowSubmitButton} />
                        </div>
                        <div className="col-12 text-center d-flex justify-content-center mt-3 mb-0">
                            <div className="px-3 py-1 " style={{ background: "#120c34", color: 'white', borderRadius: "25px" }}>
                                <h3>${campaigns.min_earn} - ${campaigns.max_earn}</h3>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 text-center mt-0">
                            <p className='my-1 ' style={{ fontWeight: "bold", fontSize: "larger" }}>Estimated earnings per post</p>
                        </div>
                        <div className="col-md-12 col-sm-12 text-center d-flex justify-content-center mt-3 pb-3">
                            <div className="p-1 set_wid60 set_wid  " style={{ background: "#120c34", color: 'white', borderRadius: "25px" }}>
                                <p className='my-1' style={{ fontWeight: "bold", fontSize: "larger" }}>Estimated earnings per post</p>
                                <textarea
                                    className="px-3"
                                    style={{
                                        width: "90%",
                                        height: '20em',
                                        borderRadius: '25px',
                                        resize: 'none',
                                        cursor: "default"
                                    }}
                                    placeholder="Tell the creators what you'd like to post"
                                    value={desc} readOnly
                                />

                                <div className="d-flex justify-content-center mt-2 px-2">
                                    <div className="card" style={{ borderRadius: '25px', width: "90%" }}>
                                        <div className="d-flex align-items-center px-3">
                                            <h4 className='my-2' style={{ color: "#120c34" }}><b>URL:</b></h4>
                                            <input
                                                value={url}
                                                className='border-0 form-control bg-transparent'
                                                style={{ borderRadius: '25px' }}
                                                type="text"
                                                placeholder="www.yourwebiste.com"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-2 px-2">
                                    <div className="card" style={{ borderRadius: '25px', width: "90%" }}>
                                        <div className="ml-1 p-2" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <h4 className='set_fonth4 ' style={{ color: "#120c34" }}><b>Attachment :</b></h4>
                                            {/* <input type="file" id="fileInput" style={{ margin: '10px 0', display: 'none' }} />
                                            <label htmlFor="fileInput" className="" style={{ cursor: 'pointer', color: "#120c34" }}>
                                                
                                            </label> */}
                                            <h4 className=' set_fonth4 ' style={{ color: "#120c34" }}><DisplayAttachments campaigns={campaigns} /></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-2 px-2 mb-2">
                                    <div className="card" style={{ borderRadius: '25px', width: "90%" }}>
                                        <div className="d-flex align-items-center px-3">
                                            <h4 className='my-2 ' style={{ color: "#120c34" }}><b>Payout :</b></h4>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <SubmitButton showSubmitButton={showSubmitButton} />
                    </div>

                    <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header" style={{ background: "#120c34", color: 'white' }}>
                                    <h2 class="modal-title" id="exampleModalLongTitle"><i class="fa-solid fa-table mr-1"></i> Status </h2>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" className='text-white' style={{ fontSize: "xx-large" }}>&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body p-0 m-0">
                                    <TableComponent />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content" style={{ borderRadius: "25px" }}>
                            <UploadForm />
                        </div>
                    </div>
                </div>



            </div>
        )
    }



    return (
        <div>
            <ManageCampaign campaignId={campaigns.id} />
            <ToastContainer />
            {/* <Apiloading_animation/> */}
        </div>
    )
}
