import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Campaign_Manage() {
    const [campaigns, setCampaigns] = useState([]);
    const backButtonStyle = {
        background: 'none',
        border: 'none',
        color: 'inherit',
        padding: 0,
        fontSize: 'inherit',
        cursor: 'pointer',
        marginBottom: '5px',
        fontSize: "25px"
    };

    const location = useLocation();
    const campaignId = new URLSearchParams(location.search).get('id');

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/${campaignId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status === 'SUCCESS') {
                setCampaigns(result.data);

            } else {
                console.error('Failed to fetch campaigns');
            }
        };

        fetchData();
    }, []);
    const campaignTypeLabels = {
        1: 'UGC',
        2: 'Posted Content',
        3: 'Growth',
        4: 'Content'
    };
    const platform = campaigns && campaigns.details ? campaigns.details.platform : '';
    const post_frequency = campaigns && campaigns.details ? campaigns.details.post_frequency : '';
    const description = campaigns && campaigns.details ? campaigns.details.description : '';
    const attachments = campaigns && campaigns.details ? campaigns.details.attachments : '';
    const url = campaigns && campaigns.details ? campaigns.details.url : '';
    const gender = campaigns && campaigns.details ? campaigns.details.gender : '';
    const maxAge = campaigns && campaigns.details ? campaigns.details.maxAge : '';
    const minAge = campaigns && campaigns.details ? campaigns.details.minAge : '';
    const endDate = campaigns && campaigns.details ? campaigns.details.endDate : '';
    const location_d = campaigns && campaigns.details ? campaigns.details.location : '';
    const interests = campaigns && campaigns.details ? campaigns.details.interests : '';
    const startDate = campaigns && campaigns.details ? campaigns.details.startDate : '';
    const costPerTarget = campaigns && campaigns.details ? campaigns.details.costPerTarget : '';
    const contentFormate = campaigns && campaigns.details ? campaigns.details.contentFormate : '';
    const maximumAudience = campaigns && campaigns.details ? campaigns.details.maximumAudience : '';
    const minimumDileverable = campaigns && campaigns.details ? campaigns.details.minimumDileverable : '';
    const estimatedParticipants = campaigns && campaigns.details ? campaigns.details.estimatedParticipants : '';
    const participantAudienceSizeMAX = campaigns && campaigns.details ? campaigns.details.participantAudienceSizeMAX : '';
    const participantAudienceSizeMIN = campaigns && campaigns.details ? campaigns.details.participantAudienceSizeMIN : '';
    // console.log("attacxhment: ", attachments)




    function DisplayAttachments({ campaigns }) {
        // Check if campaigns and campaigns.details exist, otherwise set attachments to an empty array
        const attachments = campaigns && campaigns.details && campaigns.details.attachments ? campaigns.details.attachments : [];

        // Render different UI based on the number of attachments
        if (attachments.length === 1) {
            return <a className="" href={attachments[0]} target="_blank" rel="noopener noreferrer">Click here</a>;
        } else if (attachments.length > 1) {
            return (
                <div className="dropdown">
                    <button className="bg-transparent border-0 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {attachments.length} Attachment{attachments.length > 1 ? 's' : ''} uploaded
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {attachments.map((attachment, index) => (
                            <a key={index} className="dropdown-item" href={attachment} target="_blank" rel="noopener noreferrer">Attachment {index + 1}</a>
                        ))}
                    </div>
                </div>
            );
        } else {
            return <p>No attachments available</p>;
        }
    }

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedInStatus = window.localStorage.getItem("isLogedin") === 'true';
        // console.log("t1=", loggedInStatus);
        setIsLoggedIn(loggedInStatus);

        if (!loggedInStatus) {
            navigate("/signin", { replace: true });
        }
    }, [navigate]);

    useEffect(() => {
        // console.log("t2=", isLoggedIn);
    }, [isLoggedIn]);
    // // console.log(campaignTypeLabels[campaigns.campaign_type])
    if (campaigns.campaign_type === 3) {
        // console.log("campaigns.campaign_type: ", campaigns.campaign_type)
        return (
            <div>
                <div className="container-fluid">
                    <div className="row mt-2 d-flex align-items-center">
                        <div className="col-md-1">
                            <button onClick={() => window.history.back()} style={backButtonStyle}>
                                ← Back
                            </button>
                        </div>
                        <div className="offset-2 col-md-6 text-center">
                            <input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`${campaigns.title}: ${startDate} - ${endDate}`} className="input-box1 rounded-pill p-2 text-center text-light" />
                        </div>
                        <div className="col-md-3 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <input type="text" onChange={e => this.handleInputChange('campaignTitle', e.target.value)} style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid", cursor: "pointer" }} placeholder="Edit Campaign" className="input-box rounded-pill p-1 text-center" readOnly /> */}
                            <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large" }}><i class="fa-solid fa-pen"></i> edit request</button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Budget Remaining</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                                    <div className="text-left ">
                                        <h4 className='px-3 pt-2' style={{ color: "#120c34" }}>${campaigns.budget}</h4>
                                    </div>
                                    <button className='btn btn-lg' style={{ width: "50%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '0.8em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', border: '0', fontSize: "1.1rem", borderRadius: '0', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                            Add Funds
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Deliverables</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                                    <div className="text-left ">
                                        <h4 className='px-3 pt-2' style={{ color: "#120c34" }}>$ {minimumDileverable}</h4>
                                    </div>
                                    <button className='btn btn-lg' style={{ width: "50%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '0.8em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', border: '0', fontSize: "1.1rem", borderRadius: '0', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                            View
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Engaged Target</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body  border-0 p-1 ">
                                    <div className="">
                                        <h4 className='px-3 pt-2' style={{ color: "#120c34", borderRadius: '0', padding: '9px 15px' }}>${post_frequency}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4"><input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`Campaign Goal: ${campaignTypeLabels[campaigns.campaign_type] || 'Unknown'}`} className="input-box1 rounded-pill p-2 text-center text-light" readOnly /></div>
                        <div className="col-md-4"><input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`Platform: ${platform.toUpperCase()}`} className="input-box1 rounded-pill p-2 text-center text-light" readOnly /></div>
                        <div className="col-md-4"><input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`Content Format: ${contentFormate}`} className="input-box1 rounded-pill p-2 text-center text-light" /></div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-center">
                            <h4 className='mt-2' style={{ color: "#120c34" }}>Campaign Description</h4>
                            <textarea name="" id="" cols="30" rows="5" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid",color:"#2d3146" }} className="input-box rounded-pill px-3 py-2 " placeholder={description} readOnly  />
                            <div className="card border-0 mt-3">
                                <div className="card-body" style={{ background: "#120c34", borderRadius: "25px" }}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <h3 className='text-center text-white' style={{}}>age</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={`${minAge} - ${maxAge}`} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <h3 className='text-center text-white' style={{}}>gender</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34"  }} value={gender} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>location</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34"  }} value={location_d || "NA"} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                                        <h4 className='my-2 px-2' style={{}}>URL:</h4>
                                        <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%",color:"#120c34" }} className="input-box rounded-pill p-1 border-0" value={url} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                                        <h4 className='my-2 px-2' style={{}}>Attachment:</h4>
                                        {/* <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%" }} className="input-box rounded-pill p-1 border-0" value={attachments} readOnly /> */}
                                        {/* <a to={attachments}>Click here</a> */}
                                        {/* <a href={attachments}>Click here</a> */}
                                        <DisplayAttachments campaigns={campaigns} />
                                    </div>
                                </div>

                            </div>
                            <div className="card mt-1 border-0">
                            <h4 className='mt-2 text-center' style={{ color: "#120c34" }}>Campaign Details</h4>
                                <div className="card-body border-0 text-center" style={{ background: "#120c34", borderRadius: "25px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>Maximum Audience</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={maximumAudience} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>Cost Per Target</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={costPerTarget} className="input-box rounded-pill text-center" readOnly />
                                        </div>


                                        <div className="col-md-12 mt-3">
                                            <h3 className='text-center text-white' style={{}}>Participants Audience Size</h3>
                                            <div className="d-flex">
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMIN} className="input-box rounded-pill text-center" readOnly />
                                                <h3 className='text-center text-white' style={{}}>to</h3>
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMAX} className="input-box rounded-pill text-center" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <h3 className='text-center text-white' style={{}}>Deliverables Per Participants</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "50%",color:"#120c34" }} value="1-10" className="input-box rounded-pill text-center" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row mt-2 d-flex align-items-center">
                        <div className="col-md-1">
                            <button onClick={() => window.history.back()} style={backButtonStyle}>
                                ← Back
                            </button>
                        </div>
                        <div className="offset-2 col-md-6 text-center">
                            <input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`${campaigns.title}: ${startDate} - ${endDate}`} className="input-box1 rounded-pill p-2 text-center text-light" />
                        </div>
                        <div className="col-md-3 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <input type="text" onChange={e => this.handleInputChange('campaignTitle', e.target.value)} style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid", cursor: "pointer" }} placeholder="Edit Campaign" className="input-box rounded-pill p-1 text-center" readOnly /> */}
                            <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large" }}><i class="fa-solid fa-pen"></i> edit request</button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Budget Remaining</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                                    <div className="text-center">
                                        <h4 className='px-3 pt-2 text-center' style={{ color: "#120c34" }}>${campaigns.budget}</h4>
                                    </div>
                                    <button className='btn btn-lg' style={{ width: "50%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '0.8em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', border: '0', fontSize: "1.1rem", borderRadius: '0', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                            Add Funds
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Deliverables</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                                    <div className="text-left">
                                        <h4 className='px-3 pt-2' style={{ color: "#120c34" }}>${minimumDileverable}</h4>
                                    </div>
                                    <button className='btn btn-lg' style={{ width: "50%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '0.8em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', border: '0', fontSize: "1.1rem", borderRadius: '0', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                            View
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <h4 style={{ color: "#120c34" }}>Current Participants</h4>
                            <div className="card text-white " style={{ borderRadius: "25px", border: "#120c34 2px solid" }}>
                                <div className="card-body d-flex justify-content-between align-items-center border-0 p-1 ">
                                    <div className="text-left">
                                        <h4 className='px-3 pt-2' style={{ color: "#120c34" }}>{post_frequency || "-"}</h4>
                                    </div>
                                    <button className='btn btn-lg' style={{ width: "50%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '0.8em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                                        <span style={{ color: '#fff', lineHeight: 'inherit', cursor: 'pointer', border: '0', fontSize: "1.1rem", borderRadius: '0', padding: '9px 15px', textDecoration: 'none', display: 'inline-block' }}>
                                            View
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 justify-content-center ">
                        <div className="col-md-4"><input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`Campaign Goal: ${campaignTypeLabels[campaigns.campaign_type] || 'Unknown'}`} className="input-box1 rounded-pill p-2 text-center text-light" readOnly /></div>
                        <div className="col-md-4"><input type="text" onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%", background: "#120c34" }} placeholder={`Content Format: ${contentFormate}`} className="input-box1 rounded-pill p-2 text-center text-light" readOnly /></div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 text-center">
                            <h4 className='mt-2' style={{ color: "#120c34" }}>Campaign Description</h4>
                            <textarea name="" id="" cols="30" rows="5" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid",color:"#2d3146" }} className="input-box rounded-pill px-3 py-2 " placeholder={description} readOnly />
                            <div className="card border-0 mt-3">
                                <div className="card-body" style={{ background: "#120c34", borderRadius: "25px" }}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <h3 className='text-center text-white' style={{}}>age</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={`${minAge} - ${maxAge}`} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-3">
                                            <h3 className='text-center text-white' style={{}}>gender</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={gender} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>location</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={location_d || "NA"} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                                        <h4 className='my-2 px-2' style={{}}>URL:</h4>
                                        <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%",color:"#120c34" }} className="input-box rounded-pill p-1 border-0" value={url} readOnly />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                                        <h4 className='my-2 px-2' style={{}}>Attachment:</h4>
                                        {/* <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%" }} className="input-box rounded-pill p-1 border-0" value={attachments} readOnly /> */}
                                        {/* <a href={attachments}>Click here</a> */}
                                        <DisplayAttachments campaigns={campaigns} />
                                    </div>
                                </div>

                            </div>
                            <div className="card mt-5 border-0">
                            <h4 className='mt-2 text-center' style={{ color: "#120c34" }}>Campaign Details</h4>
                                <div className="card-body border-0 text-center" style={{ background: "#120c34", borderRadius: "25px" }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>Maximum Audience</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={maximumAudience} className="input-box rounded-pill text-center" readOnly />
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className='text-center text-white' style={{}}>Cost Per Target</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={costPerTarget} className="input-box rounded-pill text-center" readOnly />
                                        </div>


                                        {/* <div className="col-md-12 mt-3">
                                            <h3 className='text-center text-white' style={{}}>Participants Audience Size</h3>
                                            <div className="d-flex">
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMIN} className="input-box rounded-pill text-center" readOnly />
                                                <h3 className='text-center text-white' style={{}}>to</h3>
                                                <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%",color:"#120c34" }} value={participantAudienceSizeMAX} className="input-box rounded-pill text-center" readOnly />
                                            </div>
                                        </div> */}
                                        <div className="col-md-12 mt-3">
                                            <h3 className='text-center text-white' style={{}}>Deliverables Per Participants</h3>
                                            <input type="text" style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "50%",color:"#120c34" }} value="1-10" className="input-box rounded-pill text-center" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
