import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DateTimeComponent = ({ isoString }) => {
    // Step 1: Parse the ISO 8601 string to a Date object
    // console.log(isoString)
    const date = new Date(isoString);

    // Check if the date is valid
    if (isNaN(date)) {
        return <p>Invalid Date</p>;
    }

    // Step 2: Format the date and time components together
    const formattedTime = date.toLocaleTimeString();

    // Step 3: Format the date component to dd/mm/yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const formattedDateTime = `${formattedTime} - ${formattedDate}`;

    return (
        formattedDateTime
    );
};


function CampaignCards({ onCardSelect }) {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleCardClick = (id) => {
        // If the same card is already selected, return early without updating the state
        if (selectedCardId === id) {
            return;
        }

        // Toggle selection: if a different card is clicked, select the new one
        setSelectedCardId(id);
    };


    useEffect(() => {
        const fetchCampaigns = async () => {
            const token = localStorage.getItem("accessToken");  // Retrieve the access token from local storage
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?campaign_type=1,2&status=2`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Include the token in the Authorization header
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status === 'SUCCESS') {
                    setCampaigns(data.data.results);
                } else {
                    console.error('Failed to fetch campaigns:', data.message);
                }
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);


    return (
        <div>
            {campaigns.map((campaign, index) => (
                <div key={campaign.id} className="card" style={{ border: selectedCardId === campaign.id ? "none" : "#120c34 2px solid", borderTopRightRadius: "25px", borderBottomRightRadius: "25px" }} onClick={() => {
                    handleCardClick(campaign.id);
                    onCardSelect(campaign);
                }}>
                    <div className="card-body my-2 py-0">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={campaign.brand?.images?.[0] || '/images/sample1.png'} alt="" style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover', border: "#120c34 3px solid" }} />
                            </div>
                            <div className="col-md-10" style={{ cursor: "pointer" }}>
                                <div style={{ textAlign: 'start' }}>
                                    <div style={{ marginBottom: '10px' }}> <h4 style={{ color: "#120c34", fontWeight: selectedCardId === campaign.id ? 'bold' : 'normal' }}>{campaign.brand?.name || 'Unknown'}</h4></div>
                                    <div><h6 style={{ color: "#120c34" }}><i> <DateTimeComponent isoString={campaign.updated_at} /></i></h6> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
const AcceptCampaignButton = ({ campaign }) => {
    const handleAcceptCampaign = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No access token found');
        return;
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign/accept/${campaign.id}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const data = await response.json();
        // console.log('Campaign accepted:', data);
        toast.success('Campaign accepted ');
        window.location.reload();
      } catch (error) {
        console.error('Failed to accept campaign:', error);
        toast.error('Error! ');
      }
    };
  
    return (
      <button onClick={handleAcceptCampaign} className='btn bg-transparent' style={{backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)',color:"white", borderRadius: "25px", fontSize: "x-large" }}>Accept request</button>
    );
  };
  const RejectCampaignButton = ({ campaign }) => {
    const handleRejectCampaign = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No access token found');
        return;
      }
  
      try {
        const response = await fetch(`https://api.embassy.io/api/campaign/v1/campaign/reject/${campaign.id}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const data = await response.json();
        // console.log('Campaign Rejected:', data);
        toast.warning('Campaign Rejected ');
        window.location.reload();
      } catch (error) {
        console.error('Failed to accept campaign:', error);
        toast.error('Error! ');
      }
    };
  
    return (
      <button onClick={handleRejectCampaign} className='btn bg-transparent' style={{border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large"}}>Reject request</button>
    );
  };
function CampaignCardsInProgress({ onCardSelect }) {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleCardClick = (id) => {
        // If the same card is already selected, return early without updating the state
        if (selectedCardId === id) {
            return;
        }

        // Toggle selection: if a different card is clicked, select the new one
        setSelectedCardId(id);
    };


    useEffect(() => {
        const fetchCampaigns = async () => {
            const token = localStorage.getItem("accessToken");  // Retrieve the access token from local storage
            try {
                // console.log("progress linl: ", process.env.REACT_APP_BASE_URL)
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign?campaign_type=1,2&status=3`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Include the token in the Authorization header
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status === 'SUCCESS') {
                    setCampaigns(data.data.results);
                } else {
                    console.error('Failed to fetch campaigns:', data.message);
                }
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        };

        fetchCampaigns();
    }, []);


    return (
        <div>
            {campaigns.map((campaign, index) => (
                <div key={campaign.id} className="card" style={{ border: selectedCardId === campaign.id ? "none" : "#120c34 2px solid", borderTopRightRadius: "25px", borderBottomRightRadius: "25px" }} onClick={() => {
                    handleCardClick(campaign.id);
                    onCardSelect(campaign);
                }}>
                    <div className="card-body my-2 py-0">
                        <div className="row">
                            <div className="col-md-2">
                                <img src={campaign.brand?.images?.[0] || '/images/sample1.png'} alt="" style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover', border: "#120c34 3px solid" }} />
                            </div>
                            <div className="col-md-10" style={{ cursor: "pointer" }} >
                                <div style={{ textAlign: 'start' }}>
                                    <div style={{ marginBottom: '10px' }}> <h4 style={{ color: "#120c34", fontWeight: selectedCardId === campaign.id ? 'bold' : 'normal' }}>{campaign.brand?.name || 'Unknown'}</h4></div>
                                    <div><h6 style={{ color: "#120c34" }}><i><DateTimeComponent isoString={campaign.updated_at} /></i></h6></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

const CampaignDetails_Check_Approval = ({ campaign }) => {
    // State to manage the API result
    const [apiResult, setApiResult] = useState(null);

    // Function to fetch the API result
    const fetchApiResult = async () => {
        try {
            setApiResult(campaign.status);
        } catch (error) {
            console.error('Error fetching API result:', error);
        }
    };

    useEffect(() => {
        fetchApiResult();
    }, []);
    // console.log("// console.log(apiResult): ", apiResult)
    return (

        <div>
            {apiResult === 2 && (
                <div className="row mt-3 " >
                <div className="col-md-4 ">
                    {/* <button className='btn bg-transparent' style={{   border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large" }}> Reject request</button> */}
                    <RejectCampaignButton campaign={campaign}/>
                </div>
                <div className="col-md-4 text-center">
                    <button className='btn bg-transparent' style={{ border: "#120c34 2px solid", borderRadius: "25px", fontSize: "x-large" }}>Counter request</button>
                </div>
                <div className="col-md-4 text-right">
                    <AcceptCampaignButton campaign={campaign} />
                </div>
            </div>
            )}
            {apiResult === 3 && (
                <div className="row mt-3">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                            <h4 className='my-2 px-2'>Accepted:</h4>
                            <input type="text" style={{ fontSize: "x-large", outline: 'none' }} className="input-box rounded-pill p-1 border-0" placeholder="Your request has been accepted" readOnly />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}>
                                Cancel request
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {apiResult === 5 && (
                <div className="row mt-3">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                            <h4 className='my-2 px-2'>Rejected:</h4>
                            <input type="text" style={{ fontSize: "x-large", outline: 'none' }} className="input-box rounded-pill p-1 border-0" placeholder="Your request has been rejected" readOnly />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}>
                                Cancel request
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};



function CampaignDetails({ campaign }) {

    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleCardClick = (id) => {
        // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
        setSelectedCardId(selectedCardId === id ? null : id);
    };
    const platformImages = {
        instagram: '/images/instagram.png',
        youtube: '/images/youtube.png',
        tiktok: '/images/tiktok.png'
    };

    if (!campaign) {
        return (
            <div className="container-fluid text-center mt-5" style={{}}>
                <i class="fa-solid fa-code-pull-request fa-3x"></i>
                <h1>Select a campaign to see details</h1>
            </div>
        );
    }

    // Assuming 'platform' is directly under 'details' and is always a string.
    const platform = campaign.details?.platform; // Access the platform information more safely
    const getImageForPlatform = (platform) => {
        return platformImages[platform.toLowerCase()] || '/images/placeholder.png';
    };

    const imageSrc = platform ? getImageForPlatform(platform) : '/images/placeholder.png';

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    <h5 className='text-center mt-1'>Instruction</h5>
                    <textarea className='p-2' cols="30" rows="14" style={{ borderRadius: '25px', border: "#120c34 3px solid" }} value={campaign.details.description || ""} readOnly />
                </div>
                <div className="col-md-8">
                    <h5 className='text-center mt-1'>Campaign Details</h5>
                    <div className="card" style={{ border: "#120c34 3px solid", borderRadius: "25px" }}>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={imageSrc} alt={`Platform: ${platform || 'Unknown'}`} style={{ width: "200px", height: "200px" }} />
                                </div>
                                <div className="col-md-8 mt-3">
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.video_story || 'None'} : three-frame Video stories`}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.photo_story || 'None'} : three-frame Photo stories`}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.photo_post || 'None'} : in-feed photo post `}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.reel || 'None'} : 15-20 sec reel`}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h3 style={{ color: "#120c34" }}>{`Frequency ${campaign.details?.post_frequency || 'None'} : per week`}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className='text-center mt-4'>Offer: Cash + Product</h5>
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        <input type="text" style={{ border: "#120c34 3px solid ", borderRadius: "25px", fontSize: "x-large", width: "80%" }} placeholder={`$${campaign.details?.product_value || 'None'}`} className="datepicker input-box rounded-pill p-1 text-center" readOnly />
                        <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "80%", border: "#120c34 3px solid" }} placeholder={`${campaign.details?.product_url || 'None'}`} className=" datepicker input-box rounded-pill p-1 text-center" readOnly />
                    </div>
                </div>
            </div>


            <div className="row mt-3">
                <div className="col-md-9">
                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                        <h4 className='my-2 px-2' style={{}}>Attachment:</h4>
                        <input type="text" style={{ fontSize: "x-large", outline: 'none' }} className="input-box rounded-pill p-1 border-0" placeholder={campaign.details.attachment || "Attachment.jpeg"} readOnly />
                    </div>
                </div>
                {/* <div className="col-md-3">
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>

                        <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}><i class="fa-solid fa-pen"></i> edit request</button>
                    </div>
                </div> */}
            </div>
            {/* <div className="row mt-3 " >
                <div className="col-md-4 ">
                    <button className='btn bg-transparent' style={{   border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}> Reject request</button>
                </div>
                <div className="col-md-4 text-center">
                    <button className='btn bg-transparent' style={{ border: "#120c34 3px solid", borderRadius: "25px", fontSize: "xx-large" }}>Counter request</button>
                </div>
                <div className="col-md-4 text-right">
                    <button className='btn bg-transparent' style={{backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)',color:"white", borderRadius: "25px", fontSize: "xx-large" }}>Accept request</button>
                </div>
            </div> */}
            {/* <h1 className='text-primary'>{campaign.status}</h1> */}
            <CampaignDetails_Check_Approval campaign={campaign}/>


        </div>
    );
}

function CampaignDetails_Progress({ campaign }) {

    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleCardClick = (id) => {
        // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
        setSelectedCardId(selectedCardId === id ? null : id);
    };
    const platformImages = {
        instagram: '/images/instagram.png',
        youtube: '/images/youtube.png',
        tiktok: '/images/tiktok.png'
    };

    if (!campaign) {
        return (
            <div className="container-fluid text-center mt-5" style={{}}>
                <i class="fa-solid fa-code-pull-request fa-3x"></i>
                <h1>Select a campaign to see details</h1>
            </div>
        );
    }

    // Assuming 'platform' is directly under 'details' and is always a string.
    const platform = campaign.details?.platform; // Access the platform information more safely
    const getImageForPlatform = (platform) => {
        return platformImages[platform.toLowerCase()] || '/images/placeholder.png';
    };

    const imageSrc = platform ? getImageForPlatform(platform) : '/images/placeholder.png';

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    <h5 className='text-center mt-1'>Instruction</h5>
                    <textarea className='p-2' cols="30" rows="14" style={{ borderRadius: '25px', border: "#120c34 3px solid" }} value={campaign.details.description || ""} readOnly />
                </div>
                <div className="col-md-8">
                    <h5 className='text-center mt-1'>Campaign Details</h5>
                    <div className="card" style={{ border: "#120c34 3px solid", borderRadius: "25px" }}>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src={imageSrc} alt={`Platform: ${platform || 'Unknown'}`} style={{ width: "200px", height: "200px" }} />
                                </div>
                                <div className="col-md-8 mt-3">
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.video_story || 'None'} : three-frame Video stories`}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.photo_story || 'None'} : three-frame Photo stories`}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.photo_post || 'None'} : in-feed photo post `}</h3>
                                    <h3 style={{ color: "#120c34" }}>{`${campaign.details?.reel || 'None'} : 15-20 sec reel`}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h3 style={{ color: "#120c34" }}>{`Frequency ${campaign.details?.post_frequency || 'None'} : per week`}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className='text-center mt-4'>Offer: Cash + Product</h5>
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        <input type="text" style={{ border: "#120c34 3px solid ", borderRadius: "25px", fontSize: "x-large", width: "80%" }} placeholder={`$${campaign.details?.product_value || 'None'}`} className="datepicker input-box rounded-pill p-1 text-center" readOnly />
                        <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "80%", border: "#120c34 3px solid" }} placeholder={`${campaign.details?.product_url || 'None'}`} className=" datepicker input-box rounded-pill p-1 text-center" readOnly />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-9">
                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                        <h4 className='my-2 px-2' style={{}}>URL:</h4>
                        <input type="text" style={{ fontSize: "x-large", outline: 'none', width: "80%" }} className="input-box rounded-pill p-1 border-0" placeholder={campaign.details.url} readOnly />
                    </div>
                </div>
                <div className="col-md-3">
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        {/* <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 3px solid" }} placeholder="Edit request" className=" datepicker input-box rounded-pill p-1 text-center" readOnly /> */}
                        {/* <button className='btn bg-transparent' style={{ display: "flex", alignItems: "center", gap: "5px", border: "#120c34 3px solid", borderRadius: "25px", fontSize: "x-large" }}><i class="fa-solid fa-pen"></i> edit request</button> */}
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-9">
                    <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                        <h4 className='my-2 px-2' style={{}}>Attachment:</h4>
                        <input type="text" style={{ fontSize: "x-large", outline: 'none' }} className="input-box rounded-pill p-1 border-0" placeholder={campaign.details.attachment || "Attachment.jpeg"} readOnly />
                    </div>
                </div>
                <div className="col-md-3">
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        {/* <input type="text" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 3px solid" }} placeholder="Cancel request" className=" datepicker input-box rounded-pill p-1 text-center" readOnly /> */}
                        {/* <button className='btn bg-transparent' style={{border: "#120c34 3px solid",borderRadius: "25px",fontSize: "x-large"}}>Cancel request</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}



export default function Manage_req() {
    const [activeTab, setActiveTab] = useState('pending');  // Default active tab
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedCampaignP, setSelectedCampaignP] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedInStatus = window.localStorage.getItem("isLogedin") === 'true';
        // console.log("t1=", loggedInStatus);
        setIsLoggedIn(loggedInStatus);

        if (!loggedInStatus) {
            navigate("/signin", { replace: true });
        }
    }, [navigate]);

    useEffect(() => {
        // console.log("t2=", isLoggedIn);
    }, [isLoggedIn]);


    const handleCardClick = (id) => {
        // Toggle selection: if the same card is clicked again, deselect it, otherwise select the new one
        setSelectedCardId(selectedCardId === id ? null : id);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setSelectedCampaign(null);
        setSelectedCampaignP(null);
    };
    const backButtonStyle = {
        background: 'none',
        border: 'none',
        color: 'inherit',
        padding: 0,
        fontSize: 'inherit',
        cursor: 'pointer',
        marginBottom: '5px',
        fontSize: "25px"
    };
    const getBackgroundColor = (tabName) => {
        return activeTab === tabName ? "#120c34" : "#fff";  // Change #fff to your default or inactive color
    };
    const getTextColor = (tabName) => {
        return activeTab === tabName ? "#fff" : "#120c34";  // Active text color #fff, inactive text color #120c34
    };

    if (!isLoggedIn) {
        return <Navigate to="/signin" replace />;
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row ">
                    <button className='ml-3' onClick={() => window.history.back()} style={backButtonStyle}>
                        ← Back
                    </button>
                </div>
                <div className="row">
                    <h3 className='ml-3'>Request</h3>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card border-0">
                            <div className="card-body " style={{ border: "#120c34 3px solid", background: getBackgroundColor('pending'), borderTopLeftRadius: '25px', borderTopRightRadius: '25px', }}>
                                <h3 className=' text-center' style={{ cursor: "pointer", color: getTextColor('pending') }} onClick={() => handleTabClick('pending')}>Pending</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card border-0">
                            <div className="card-body " style={{ border: "#120c34 3px solid", background: getBackgroundColor('in progress'), borderTopLeftRadius: '25px', borderTopRightRadius: '25px', }}>
                                <h3 className=' text-center' style={{ cursor: "pointer", color: getTextColor('in progress') }} onClick={() => handleTabClick('in progress')}>In Progress</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card border-0">
                            <div className="card-body " style={{ border: "#120c34 3px solid", background: getBackgroundColor('completed'), borderTopLeftRadius: '25px', borderTopRightRadius: '25px', }}>
                                <h3 className=' text-center' style={{ cursor: "pointer", color: getTextColor('completed') }} onClick={() => handleTabClick('completed')}>Completed</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row container-fluid m-0" style={{ background: "#120c34" }}>
                    {activeTab === 'pending' &&

                        <div className="container-fluid mt-4 bg-white">
                            <div className="row">
                                <div className="col-md-4 px-0" style={{ overflowY: 'scroll', height: '70vh' }}>

                                    <CampaignCards onCardSelect={setSelectedCampaign} />

                                </div>
                                <div className="col-md-8 px-0">
                                    <CampaignDetails campaign={selectedCampaign} />
                                </div>
                            </div>
                        </div>
                    }
                    {activeTab === 'in progress' &&
                        <div className="container-fluid mt-4 bg-white">
                            <div className="row">
                                <div className="col-md-4 px-0" style={{ overflowY: 'scroll', height: '70vh' }}>

                                    <CampaignCardsInProgress onCardSelect={setSelectedCampaignP} />

                                </div>
                                <div className="col-md-8 px-0">
                                    <CampaignDetails_Progress campaign={selectedCampaignP} />
                                </div>
                            </div>
                        </div>
                    }
                    {activeTab === 'completed' && <div>Content for Completed</div>}

                </div>

            </div>
            <ToastContainer />

        </div>
    )
}
