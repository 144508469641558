import React, { Component, useState, useEffect } from 'react'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './campaign_create.css'
import { Navigate, useNavigate } from 'react-router-dom';


export default class Campaign_Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      contentGoal: '',
      campaignTitle: '',
      startDate: '',
      endDate: '',
      campaignDesc: '',
      url: '',
      attachment: '',
      fileCount: 0,
      Platform: '',
      contentFormate: '',
      minimumDileverable: '',
      minAge: '',
      maxAge: '',
      location: '',
      gender: '',
      interests: '',
      maximumBudget: '',
      maximumAudience: '',
      participantAudienceSizeMIN: '',
      participantAudienceSizeMAX: '',
      estimatedParticipants: '',
      costPerTarget: 0,


      selectedOptions: [],





      currentStep: 1,
    }
  };
  handleFileChange = (event) => {
    const files = event.target.files; // Capture the file list from the input
    const fileCount = files.length; // Get the number of files

    // Update state with the new file count and the files themselves
    this.setState({
      fileCount,
      files: Array.from(files) // Convert FileList to Array if you need to manipulate it later
    });
  }

  isLogedin = () => {
    const isLogedin = window.localStorage.getItem("isLogedin")
    // console.log(isLogedin)
    return isLogedin
  }
  handleExpInput = (event) => {
    let input = event.target.value.replace(/[^\d]/g, ''); // Remove non-digits

    // Automatically add slashes
    if (input.length > 2 && input.length <= 4) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    }
    if (input.length > 4) {
      input = input.slice(0, 2) + '/' + input.slice(2, 4) + '/' + input.slice(4, 8);
    }

    // Validate the input before returning
    if (/^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(input)) {
      return input; // Return the formatted date
    }
    return event.target.value; // Return original input if it doesn't match the format
  };

  onDate1 = (event) => {
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputChange('startDate', formattedDate);
  };
  onDate2 = (event) => {
    const formattedDate = this.handleExpInput(event); // Get the formatted date
    this.handleInputChange('endDate', formattedDate);
  };

  // handleInputChange = (key, value) => {
  //   this.setState({ [key]: value }, () => {
  //     // console.log(`Updated ${key}:`, this.state[key]); // Log new state value
  //   });
  // }
  handleInputChange = (field, value) => {
    // console.log(field, value); // Add this line to debug
    this.setState({
      [field]: value
    });
  };
  nextStep = (event) => {
    event.preventDefault();
    const { currentStep } = this.state;
    let valid = true;

    try {
      switch (currentStep) {
        case 1:
          if (!this.state.contentGoal) {
            toast.warning('Please select a campaign goal.');
            valid = false;
          }
          break;
        case 2:
          if (!this.state.campaignTitle || !this.state.startDate || !this.state.endDate || !this.state.campaignDesc || !this.state.url) {
            toast.warning('Please fill out all campaign details.');
            valid = false;
          } else {
            // Validate dates
            this.convertDate(this.state.startDate);
            this.convertDate(this.state.endDate);
          }
          break;
        case 3:
          if (this.state.contentGoal === "Content") {
            this.setState({ Platform: "" });
            if (!this.state.contentFormate || !this.state.minimumDileverable) {
              toast.warning('Please select content format and provide maximum deliverable.');
              valid = false;
            }
          } else if (!this.state.Platform || !this.state.contentFormate || !this.state.minimumDileverable) {
            toast.warning('Please select platform, content format and provide maximum deliverable.');
            valid = false;
          }
          break;
        case 4:
          // console.log(this.state.minAge ,this.state.maxAge ,this.state.location ,this.state.gender ,this.state.interests)
          if (!this.state.minAge || !this.state.maxAge || !this.state.location || !this.state.gender || !this.state.interests) {
            toast.warning('Please fill out all target demographics.');
            valid = false;
          }
          break;
        case 5:
          if (!this.state.maximumBudget || !this.state.maximumAudience || !this.state.participantAudienceSizeMIN || !this.state.participantAudienceSizeMAX || !this.state.estimatedParticipants || !this.state.costPerTarget) {
            toast.warning('Please fill out all budget and payout details.');
            valid = false;
          }
          break;
        default:
          break;
      }

      if (valid) {
        this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };


  prevStep = () => {
    if (this.state.currentStep === 1) {
      this.setState({ showForm: false });
    } else {
      this.setState(prevState => ({ currentStep: prevState.currentStep - 1 }));
    }
  };

  convertDate = (inputDate) => {
    // Parse the date from MM/DD/YYYY format
    const dateParts = inputDate.split("/");
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[0], 10) - 1; // Adjust month from 1-12 to 0-11 for JS Date
    const day = parseInt(dateParts[1], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as YYYY-MM-DDT00:00:00.000000Z
    const isoString = date.toISOString(); // Returns YYYY-MM-DDTHH:MM:SS.sssZ
    const startDatetime = isoString.split('T')[0] + 'T00:00:00.000000Z'; // Restrict time to 00:00:00.000000Z

    // Example to show how to use it in state, if needed
    // this.setState({ start_datetime: startDatetime });

    return startDatetime;
  };



  handleSubmit = async (event) => {
    event.preventDefault();  // Prevent form submission behavior
    // console.log("Form Data:", this.state);

    const files = this.state.files;
    // Retrieve the access token from local storage
    const token = localStorage.getItem("accessToken");

    // Fetch upload URLs for attachments if files are to be uploaded
    const fileCount = this.state.fileCount;  // Assuming you have fileCount in your state
    let attachments = [];


    const uploadFileToAzure = async (file, sasUrl) => {
      try {
        const response = await fetch(sasUrl, {
          method: 'PUT',
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type || 'application/octet-stream',
          },
          body: file,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file.');
        }
        // console.log('File uploaded successfully to:', sasUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
        throw error;  // Rethrow to handle in the calling function
      }
    };

    // Function to get upload URLs from the API
    if (files.length > 0) {
      // Create an object to count files by extension
      const fileCountByExtension = files.reduce((acc, file) => {
        const ext = file.name.split('.').pop();
        acc[ext] = (acc[ext] || 0) + 1;
        return acc;
      }, {});

      // Convert the object into the required format for the API
      const uploadUrlBody = {
        count: Object.entries(fileCountByExtension).map(([extension, count]) => ({
          extension,
          count
        }))
      };

      const uploadUrlOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(uploadUrlBody)
      };

      try {
        const uploadUrlResponse = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/upload-url`, uploadUrlOptions);
        const uploadUrlData = await uploadUrlResponse.json();

        if (!uploadUrlResponse.ok) {
          throw new Error(`Failed to obtain upload URLs: ${uploadUrlData.error || 'Unknown Error'}`);
        }

        // console.log('Upload URLs:', uploadUrlData);
        if (uploadUrlData.status === "SUCCESS") {
          // Upload each file to the provided URL
          attachments = uploadUrlData.data.map(item => item.key);
          for (let i = 0; i < files.length; i++) {
            await uploadFileToAzure(files[i], uploadUrlData.data[i].url);
          }
        }
      } catch (error) {
        ;
        console.error('Error fetching upload URLs:', error);
        return; // Stop the submission if file upload URLs cannot be obtained
      }
    }


    // Construct the request body with data from your component's state and obtained attachments
    const requestBody = {
      title: this.state.campaignTitle,
      status: 3,
      creator_id: 1,
      brand_id: 1,
      start_datetime: this.convertDate(this.state.startDate),
      campaign_type: this.state.contentGoal === "Growth" ? 3 : 4,
      budget: parseInt(this.state.maximumBudget),
      details: {
        platform: this.state.Platform,
        description: this.state.campaignDesc,
        url: this.state.url,
        attachments: attachments,
        minimumDileverable: this.state.minimumDileverable,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        contentFormate: this.state.contentFormate,
        minAge: this.state.minAge,
        maxAge: this.state.maxAge,
        location: this.state.location,
        gender: this.state.gender,
        interests: this.state.interests,
        maximumAudience: this.state.maximumAudience,
        participantAudienceSizeMIN: this.state.participantAudienceSizeMIN,
        participantAudienceSizeMAX: this.state.participantAudienceSizeMAX,
        estimatedParticipants: this.state.estimatedParticipants,
        costPerTarget: this.state.costPerTarget,
      }
    };

    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/campaign/v1/campaign`, fetchOptions);
      const responseData = await response.json();
      // console.log('API Response:', responseData);

      if (response.ok) {
        // console.log('Campaign created successfully:', responseData);
        toast.success('Campaign created successfully! ');
        window.location.href = '/campaigns_dashboard';
      } else {
        console.error('Failed to create campaign:', responseData);
        toast.error('Failed to create campaign! ');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  handleDateSelect = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ startDate: formattedDate });
  };
  handleDateSelect1 = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ endDate: formattedDate });
  };

  handleDateChange = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ startDate: date });
    this.handleInputFormChange('startDate', formattedDate);
  };

  handleDateChange1 = (date) => {
    const formattedDate = format(date, 'MM/dd/yyyy');
    this.setState({ endDate: date });
    this.handleInputFormChange('endDate', formattedDate);
  };
  handleExpInput = (event) => {
    const date = new Date(event.target.value);
    return format(date, 'MM/dd/yyyy');
  };


  handleInputFormChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(`Updated ${key}:`, this.state[key]);
    });
  }





  renderForm() {
    const { currentStep, fileCount, location } = this.state; // Destructure state variables

    const MultiSelectDropdown = ({ handleInputFormChange, parentLocation }) => {
      const [selectedOptions, setSelectedOptions] = useState([]);
      const [options, setOptions] = useState([]);

      useEffect(() => {
        const fetchOptions = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/general/v1/state`);
            const data = await response.json();
            if (data.status === 'SUCCESS') {
              const apiOptions = data.data.map((item) => ({
                value: item.id,
                label: `${item.name} - ${item.subcountry}`,
              }));
              setOptions(apiOptions);
            }
          } catch (error) {
            console.error('Error fetching options:', error);
          }
        };

        fetchOptions();
      }, []);

      const handleChange = (selected) => {
        setSelectedOptions(selected);
        // console.log("Selected options:", selected);
        const selectedLabels = selected ? selected.map(option => option.label).join(', ') : '';

        // handleState(selectedLabels)
        this.state.location=selectedLabels
      };
      // const handleState = (selectedLabels) => {
      //   // console.log(selectedLabels)
      //   this.handleInputFormChange('location', selectedLabels);
      // };

      return (
        <div>
          <Select
            value={selectedOptions}
            onChange={handleChange}
            options={options}
            isMulti
            placeholder="Select locations..."
            styles={{
              control: (base) => ({
                ...base,
                border: '2px solid #120c34',
                borderRadius: '25px',
                fontSize: 'x-large',
                width: '100%',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#f0f0f0',
                borderRadius: '15px',
                padding: '5px',
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: '#333',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#333',
                ':hover': {
                  backgroundColor: '#ddd',
                  color: '#120c34',
                },
              }),
            }}
            className="input-box rounded-pill p-2 text-center"
          />
          <input
            type="text"
            value={parentLocation}
            onChange={e => handleInputFormChange('location', e.target.value)}
            style={{ border: '#120c34 2px solid', borderRadius: '25px', fontSize: 'x-large', width: '100%', display: "none" }}
            placeholder="ex: Los Angeles, CA"
            className="input-box rounded-pill p-2 text-center"
          />
        </div>
      );
    };

    switch (currentStep) {
      case 1:
        return ( // You need to add a return statement here
          <div>
            <div className="container text-center mb-5 px-5">
              <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                <li className='active ' id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
              </div>
            </div>
            <div className="container text-center px-5 mb-5">
              <h3 className='mb-4'>Campaign Goal</h3>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '20px' }}>
                <input onClick={e => this.handleInputChange('contentGoal', e.target.value)} type="button" className="set_wid btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentGoal === 'Growth' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentGoal === 'Growth' ? '#120c34' : 'white', borderRadius: "25px", border: "#120c34 2px solid" }} value="Growth" />
                <input onClick={e => this.handleInputChange('contentGoal', e.target.value)} type="button" className="set_wid btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentGoal === 'Content' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentGoal === 'Content' ? '#120c34' : 'white', borderRadius: "25px", border: "#120c34 2px solid" }} value="Content" />
              </div>
              <p className='hide' style={{ color: "#120c34", fontSize: "x-large" }}>Your customer will create content for your brand and <br /> post it on their own social media accounts </p>
              <p className='hidden-on-web' style={{ color: "#120c34", fontSize: "larger" }}>Your customer will create content for your brand and post it on their own social media accounts </p>
              <div className="row text-end d-flex justify-content-end mr-md-5 px-md-5">
                <button onClick={this.nextStep} className='btn btn-lg' style={{ letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                  <span style={{
                    color: '#fff',
                    lineHeight: 'inherit',
                    cursor: 'pointer',
                    border: '0',
                    borderRadius: '0',
                    padding: '9px 15px',
                    textDecoration: 'none',
                    display: 'inline-block'
                  }}>
                    Next step
                  </span>
                </button>
              </div>
            </div>
          </div>
        );
      // Cases for other steps should be defined here if they exist.
      case 2:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                <li id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='active' id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
              </div>
            </div>

            <div className="container text-center ">
              <h3 className='mb-4'>Campaign Details</h3>
              <div className="row">
                <div className="col-md-6">
                  <input type="text" value={this.state.campaignTitle} onChange={e => this.handleInputChange('campaignTitle', e.target.value)} style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid" }} placeholder="Campaign Title" className="input-box rounded-pill p-1 px-4" />
                </div>
                <div className="col-md-6 give_some_margin_top">
                  <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                    {/* <input type="text" onChange={this.onDate1} value={this.state.startDate} style={{ border: "#120c34 2px solid ", borderRadius: "25px", width: "80%" }} placeholder="MM/DD/YYYY" className="set_btn_font  datepicker input-box rounded-pill p-1 text-center" /> */}
                    <DatePicker
                      selected={this.state.startDate ? parse(this.state.startDate, 'MM/dd/yyyy', new Date()) : null}
                      onSelect={this.handleDateSelect}
                      onChange={this.handleDateChange}
                      dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
                      placeholderText="mm/dd/yyyy"
                      minDate={new Date()}
                      customInput={
                        <input
                          type="text"
                          onChange={this.onDate1}
                          value={this.state.startDate}
                          style={{
                            border: "#120c34 2px solid",
                            borderRadius: "25px",
                            width: "80%",
                          }}
                          placeholder="MM/DD/YYYY"
                          className="set_btn_font datepicker input-box rounded-pill p-1 text-center"
                        />
                      }
                    />
                    <h3>to</h3>
                    {/* <input type="text" onChange={this.onDate2} value={this.state.endDate} style={{ borderRadius: "25px", width: "80%", border: "#120c34 2px solid" }} placeholder="MM/DD/YYYY" className="set_btn_font datepicker input-box rounded-pill p-1 text-center" /> */}
                    <DatePicker
                      selected={this.state.endDate ? parse(this.state.endDate, 'MM/dd/yyyy', new Date()) : null}
                      onSelect={this.handleDateSelect1}
                      onChange={this.handleDateChange1}
                      minDate={new Date()}
                      placeholderText="mm/dd/yyyy"
                      dateFormat="MM/dd/yyyy" // Ensures date format is MM/DD/YYYY in the picker
                      customInput={
                        <input
                          type="text"
                          onChange={this.onDate2}
                          value={this.state.endDate}
                          style={{
                            border: "#120c34 2px solid",
                            borderRadius: "25px",
                            width: "80%",
                          }}
                          placeholder="MM/DD/YYYY"
                          className="set_btn_font datepicker input-box rounded-pill p-1 text-center"
                        />
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <textarea value={this.state.campaignDesc} onChange={e => this.handleInputChange('campaignDesc', e.target.value)} name="" row="25" id="" style={{ borderRadius: "25px", fontSize: "x-large", width: "100%", border: "#120c34 2px solid" }} className="input-box rounded-pill p-1  px-2 mt-3" placeholder='Campaign Description...' />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex align-items-center" style={{ border: "#120c34 2px solid ", borderRadius: "25px" }}>
                    <h4 className='my-2 px-2' style={{}}>URL:</h4>
                    <input type="text" value={this.state.url} onChange={e => this.handleInputChange('url', e.target.value)} style={{ fontSize: "x-large", outline: 'none', width: "100%", margin: "6px" }} className="input-box rounded-pill p-1 border-0" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end give_some_margin_top">
                  <div className="d-flex align-items-center px-3 text-end" style={{ border: "#120c34 2px solid ", borderRadius: "25px", width: "100%" }}>
                    <input type="file" id="fileInput" style={{ margin: '10px 0', display: 'none' }} multiple onChange={this.handleFileChange} />
                    <label htmlFor="fileInput" className="" style={{ cursor: 'pointer', color: "#120c34" }}>
                      <div className="">
                        <p style={{ fontSize: "larger" }} className='my-2'> {fileCount > 0 ? `(${fileCount} uploaded)` : "Add attachment (Optional)"}</p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">

                  <button type="button" onClick={this.prevStep} className="give_wid btn bg-transparent btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                    Previous Step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button className='btn btn-lg' onClick={this.nextStep} style={{ width: "90%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Next
                    </span>
                  </button>
                </div>
              </div>


            </div>
          </div>
        )
      case 3:
        if (this.state.contentGoal == "Content") {
          return (
            <div>
              <div className="container text-center mb-5 px-5">
                <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                  <li id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='active' id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className='mt-5 text-center' style={{}}>Content Format</h3>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '20px' }}>
                      <input onClick={e => this.handleInputChange('contentFormate', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentFormate === 'Photo' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentFormate === 'Photo' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", border: "#120c34 2px solid" }} value="Photo" />
                      <input onClick={e => this.handleInputChange('contentFormate', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentFormate === 'Video' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentFormate === 'Video' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", border: "#120c34 2px solid" }} value="Video" />
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <h3 className='text-center' style={{}}>Maximum Dileverable</h3>
                    <input type="number" value={this.state.minimumDileverable} onChange={e => this.handleInputChange('minimumDileverable', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "30%" }} placeholder="1-10" className="input-box rounded-pill p-2 text-center" />
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-6 col-sm-6 d-flex justify-content-start">

                    <button type="button" onClick={this.prevStep} className="give_wid btn bg-transparent btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                      Previous Step
                    </button>
                  </div>
                  <div className="col-6 col-sm-6 d-flex justify-content-end">
                    <button className='btn btn-lg' onClick={this.nextStep} style={{ width: "90%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                      <span style={{
                        color: '#fff',
                        lineHeight: 'inherit',
                        cursor: 'pointer',
                        // backgroundColor: '#3898ec',
                        border: '0',
                        borderRadius: '0',
                        padding: '9px 15px',
                        textDecoration: 'none',
                        display: 'inline-block'
                      }}>
                        Next
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className="container text-center mb-5 px-5">
                <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                  <li id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='active' id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                  <li id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                </div>
              </div>
              <div className="container">
                <h3 className='mt-5 text-center' style={{}}>Platform</h3>
                <div className="row">
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo1">
                      <img src="/images/instagram.png" alt="Instagram Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo1" name="logo" value="instagram" />

                    </label>
                  </div>
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo2">
                      <img src="/images/tiktok.png" alt="TikTok Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo2" name="logo" value="tiktok" />
                    </label>
                  </div>
                  <div className="col-4 col-sm-4 text-center">
                    <label htmlFor="logo3">
                      <img src="/images/youtube.png" alt="YouTube Logo" style={{ width: '100px', height: '100px', display: 'block', margin: 'auto' }} />
                      <input type="radio" onClick={e => this.handleInputChange('Platform', e.target.value)} id="logo3" name="logo" value="youtube" />
                    </label>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 text-center">
                    <h3 className='text-center' style={{}}>Content Format</h3>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '20px' }}>
                      <input onClick={e => this.handleInputChange('contentFormate', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentFormate === 'Photo' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentFormate === 'Photo' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", border: "#120c34 2px solid" }} value="Photo" />
                      <input onClick={e => this.handleInputChange('contentFormate', e.target.value)} type="button" className="btn btn-lg rounded-pill mx-3" style={{ color: this.state.contentFormate === 'Video' ? 'white' : '#120c34', fontSize: "x-large", backgroundColor: this.state.contentFormate === 'Video' ? '#120c34' : 'white', width: '30%', borderRadius: "25px", border: "#120c34 2px solid" }} value="Video" />
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <h3 className='text-center' style={{}}>Maximum Dileverable</h3>
                    <input type="number" value={this.state.minimumDileverable} onChange={e => this.handleInputChange('minimumDileverable', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "30%" }} placeholder="1-10" className="input-box rounded-pill p-2 text-center" />
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-6 col-sm-6 d-flex justify-content-start">

                    <button type="button" onClick={this.prevStep} className="give_wid btn bg-transparent btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                      Previous Step
                    </button>
                  </div>
                  <div className="col-6 col-sm-6 d-flex justify-content-end">
                    <button className='btn btn-lg' onClick={this.nextStep} style={{ width: "90%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                      <span style={{
                        color: '#fff',
                        lineHeight: 'inherit',
                        cursor: 'pointer',
                        // backgroundColor: '#3898ec',
                        border: '0',
                        borderRadius: '0',
                        padding: '9px 15px',
                        textDecoration: 'none',
                        display: 'inline-block'
                      }}>
                        Next
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          )
        }
      case 4:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                <li id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='active' id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
              </div>
            </div>
            <h3 className='text-center mb-2'>Target Demographics</h3>
            <div className="container text-center  mt-4">
              <div className="row">
                <div className="col-md-6">
                  <h3 className=' text-center' style={{}}>Age</h3>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '20px' }}>
                    <input type="number" value={this.state.minAge} onChange={e => this.handleInputChange('minAge', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px" }} min="18" max="100" placeholder="Min" className="set_wid set_btn_font input-box rounded-pill p-2 text-center " required />
                    <input type="text" value={this.state.maxAge} onChange={e => this.handleInputChange('maxAge', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px" }} placeholder="Max" className="set_wid set_btn_font input-box rounded-pill p-2 text-center" required />
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <h3 className='text-center' style={{}}>Location</h3>
                  {/* <input type="text" value={this.state.location} onChange={e => this.handleInputChange('location', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="ex: Los Angeles, CA" className="input-box rounded-pill p-2 text-center" /> */}
                  <MultiSelectDropdown handleInputFormChange={this.handleInputFormChange} location={location} />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 text-center">
                  <h3 className='text-center' style={{}}>Gender</h3>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '20px' }}>
                    <input onClick={e => this.handleInputChange('gender', e.target.value)} type="button" className="set_wid set_btn_font input-box rounded-pill p-2 text-center " style={{ color: this.state.gender === 'Male' ? 'white' : '#120c34', backgroundColor: this.state.gender === 'Male' ? '#120c34' : 'white', borderRadius: "25px", border: "#120c34 2px solid" }} value="Male" />
                    <input onClick={e => this.handleInputChange('gender', e.target.value)} type="button" className="set_wid set_btn_font input-box rounded-pill p-2 text-center " style={{ color: this.state.gender === 'Female' ? 'white' : '#120c34', backgroundColor: this.state.gender === 'Female' ? '#120c34' : 'white', borderRadius: "25px", border: "#120c34 2px solid" }} value="Female" />
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <h3 className='text-center' style={{}}>Interests</h3>
                  <input type="text" value={this.state.interests} onChange={e => this.handleInputChange('interests', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="ex: Fitness" className="input-box rounded-pill p-2 text-center" />
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">

                  <button type="button" onClick={this.prevStep} className="give_wid btn bg-transparent btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                    Previous Step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button className='btn btn-lg' onClick={this.nextStep} style={{ width: "90%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Next
                    </span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        )
      case 5:
        return (
          <div>
            <div className="container text-center mb-5 px-5">
              <div id='campaign_progress' style={{ display: 'flex', justifyContent: 'center', gap: '1px', marginBottom: '20px' }}>
                <li id='step1' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step2' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step3' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li id='step4' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='hide' style={{ color: '#120c34', fontSize: '30px', listStyleType: 'none' }}>•</li>
                <li className='active' id='step5' style={{ color: '#120c34', fontSize: '24px', listStyleType: 'none' }}></li>
              </div>
            </div>
            <h3 className='text-center mb-2'>Campaign budget and payout</h3>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h3 className='mt-3 text-center' style={{}}>Maximum Budget</h3>
                  <input type="text" value={this.state.maximumBudget} onChange={e => this.handleInputChange('maximumBudget', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="1-10" className="input-box rounded-pill p-2 text-center" />
                </div>
                <div className="col-md-4">
                  <h3 className='mt-3 text-center' style={{}}>Maximum Audience</h3>
                  <input type="text" value={this.state.maximumAudience} onChange={e => this.handleInputChange('maximumAudience', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="1-10" className="input-box rounded-pill p-2 text-center" />
                </div>
                <div className="col-md-4">
                  <h3 className='mt-3 text-center' style={{}}>Participants audience size</h3>
                  <div className='' style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '50px' }}>
                    <input type="text" value={this.state.participantAudienceSizeMIN} onChange={e => this.handleInputChange('participantAudienceSizeMIN', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="Min" className="input-box rounded-pill p-2 text-center " />
                    <input type="text" value={this.state.participantAudienceSizeMAX} onChange={e => this.handleInputChange('participantAudienceSizeMAX', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", fontSize: "x-large", width: "100%" }} placeholder="Max" className="input-box rounded-pill p-2 text-center" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 text-center px-0">
                  <h3 className='text-center  ' >Estimated Participants</h3>
                  <input type="text" value={this.state.estimatedParticipants} onChange={e => this.handleInputChange('estimatedParticipants', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", background: "#120c34" }} placeholder="min-max" className="  input-box1 set_btn_font1 set_btn_font rounded-pill p-2 text-center text-light" />
                </div>
                <div className="col-6 col-sm-6 text-center px-0">
                  <h3 className='text-center  ' >Cost per target</h3>
                  <input type="text" value={this.state.costPerTarget} onChange={e => this.handleInputChange('costPerTarget', e.target.value)} style={{ border: "#120c34 2px solid ", borderRadius: "25px", background: "#120c34" }} placeholder="1-10" className="give_some_margin_top1 set_btn_font1 set_btn_font  input-box1 rounded-pill p-2 text-center text-white" />
                </div>
              </div>
            </div>

            <div className="container mb-5">
              <div className="row mt-5">
                <div className="col-6 col-sm-6 d-flex justify-content-start">

                  <button type="button" onClick={this.prevStep} className="give_wid btn bg-transparent btn-lg" style={{ color: "#120c34", border: "#120c34 3px solid", borderRadius: "25px" }}>
                    Previous Step
                  </button>
                </div>
                <div className="col-6 col-sm-6 d-flex justify-content-end">
                  <button className='btn btn-lg' style={{ width: "90%", letterSpacing: '-.02em', textTransform: 'none', backgroundColor: '#3898ec', backgroundImage: 'linear-gradient(47deg,#7d90c9 30%,#6dcaf0)', borderRadius: '25px', marginLeft: '-32px', fontFamily: 'sofia-pro,sans-serif', fontSize: '1.2em', fontWeight: 600, lineHeight: 1, transition: 'transform .3s', display: 'inline-block' }}>
                    <span style={{
                      color: '#fff',
                      lineHeight: 'inherit',
                      cursor: 'pointer',
                      // backgroundColor: '#3898ec',
                      border: '0',
                      borderRadius: '0',
                      padding: '9px 15px',
                      textDecoration: 'none',
                      display: 'inline-block'
                    }}>
                      Submit
                    </span>
                  </button>
                </div>
              </div>
            </div>


          </div>
        )
    }

  }


  renderModalContent() {
    return (
      // <form onSubmit={this.handleSubmit}>
      <form onSubmit={this.handleSubmit}>
        {this.renderForm()}
      </form>
    );

  }
  render() {
    if (!this.isLogedin()) {
      // Redirect to /signin if not logged in
      return <Navigate to="/signin" replace />;
    }
    return (
      <div>
        {this.renderModalContent()}
        <ToastContainer />
      </div>
    )
  }
}
